import { AppContainer } from "./styled";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ContextProvider } from "./components/Context";
import { Toaster } from "react-hot-toast";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { bsc } from "viem/chains";
import { AnimatePresence } from "framer-motion";
import DashboardLayout from "./Layout/Dashboard";
import AiChat from "./page/AiChat";
import { useEffect, useState } from "react";
import Register from "./page/Auth/Register";
import Forgot from "./page/Auth/Forgot";
import { ApolloProvider } from "@apollo/client";
import { client } from "./services/graphApi";
import AuthLayout from "./Layout/Auth";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Login2FA from "./page/Auth/Login/2FA";
import Reset2FA from "./page/Auth/Reset";
import Verify from "./page/Auth/Verify";
import {
  Account,
  Affiliate,
  Bonus,
  CombineWallet,
  DashboardHome,
  History,
  Invest,
  Support,
  Swap,
  WalletAIRDROP,
} from "./router/elements";
import Login from "./page/Auth/Login";
import HomePhase2 from "./page/Homev2";
import HomeLayout from "./Layout/Home";
import LoginToken from "./page/Auth/LoginToken";
import ModalNotification from "./components/ModalNoti";

const projectId = "17c32b7d53c43402b9413971337ef0b2";
const chains = [bsc];
const wagmiConfig: any = defaultWagmiConfig({ chains, projectId });
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: "dark",
});

const App = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  const idToken = localStorage.getItem("idToken");

  useEffect(() => {
    const storedLanguage = localStorage.getItem("languageSelected");
    if (!storedLanguage) {
      localStorage.setItem("languageSelected", "English");
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <WagmiConfig config={wagmiConfig}>
          <ContextProvider>
            <Toaster position="top-right" reverseOrder={false} />
            <AppContainer>
              <AnimatePresence mode="wait">
                <Routes>
                  {!idToken ? (
                    <Route path="/auth" element={<AuthLayout />}>
                      <Route path="login" element={<Login />} />
                      <Route path="register" element={<Register />} />
                      <Route path="reset" element={<Forgot />} />
                      <Route path="verify" element={<Verify />} />
                      <Route path="login-2fa" element={<Login2FA />} />
                      <Route path="reset-2fa" element={<Reset2FA />} />
                      <Route path="login-token/:id" element={<LoginToken />} />
                    </Route>
                  ) : (
                    <Route element={<DashboardLayout />}>
                      <Route
                        path="dashboard"
                        element={<DashboardHome />}
                        index
                      />
                      <Route path="account" element={<Account />} />
                      <Route path="affiliate" element={<Affiliate />} />
                      <Route path="bonus" element={<Bonus />} />
                      <Route path="swap" element={<Swap />} />
                      <Route path="history" element={<History />} />
                      <Route path="investment" element={<Invest />} />
                      <Route path="wallet" element={<CombineWallet />} />
                      <Route
                        path="wallet/airdrop"
                        element={<WalletAIRDROP />}
                      />
                      <Route path="support" element={<Support />} />
                    </Route>
                  )}
                  <Route path="/" element={<HomeLayout />}>
                    <Route path="/" element={<HomePhase2 />} />
                    {/* <Route path="/buy-solars" element={<BuyAMFI />} /> */}
                    <Route path="/ai-chat" element={<AiChat />} />
                    {/* <Route path="/ai-box" element={<AiMysteryBox />} /> */}
                  </Route>
                  {/* <Route element={<AuthLayout />}>
                    <Route index path="/swap-amfi" element={<SwapAMFI />} />
                  </Route> */}
                  <Route
                    path="*"
                    element={<Navigate to={idToken ? "/dashboard" : "/"} />}
                  />
                </Routes>
              </AnimatePresence>
            </AppContainer>
          </ContextProvider>
        </WagmiConfig>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
