import styled from "styled-components";

export const ModalNotificationWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 9000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
`;
export const ModalNotificationInner = styled.div`
  position: relative;
  color: #fff;
  max-width: 450px;
  padding: 30px;
  width: 100%;
  border: 1px solid rgb(112, 228, 102, 0.3);
  background-color: rgb(0, 0, 0, 0.8);
  border-radius: 10px;
  h2 {
    font-size: 25px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
  }
  p {
    text-align: center;
    letter-spacing: 0.05rem;
    font-size: 14px;
    line-height: 1.5;
  }
  .icon-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  figure {
    width: max-content;
    margin: 0 auto 20px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
    p {
      font-size: 12px;
    }
  }
`;
