import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAEClpn_Ho_pWIvITP2Hm-Bb9EVLNboOjg",
  authDomain: "aisol-2f430.firebaseapp.com",
  projectId: "aisol-2f430",
  storageBucket: "aisol-2f430.appspot.com",
  messagingSenderId: "892416536879",
  appId: "1:892416536879:web:e097df99ebca33afb8b1da",
  measurementId: "G-ZEPPH2YEEJ",

  // AIMETAFI
  // apiKey: "AIzaSyCNsRbAKuQhy7sq0VJ4tNkdc9hb-Q2wzew",
  // authDomain: "amfi-9999.firebaseapp.com",
  // projectId: "amfi-9999",
  // storageBucket: "amfi-9999-public",
  // messagingSenderId: "521991400914",
  // appId: "1:521991400914:web:e550de6e85065b9d85ac0b",
  // measurementId: "G-PDZG8LNN29",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export default app;
