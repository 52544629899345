import { BackgroundBanner } from "../../Layout/Home/styled";
import AMFIToken from "../../components/AMFI Token";
import Banner from "../../components/Banner";
import ClearlyRoadmap from "../../components/ClearlyRoadmap";
import Ecosystem from "../../components/Ecosystem";
import ExpressYourLimitation from "../../components/ExpressYourLimitation";
import VersionAndMission from "../../components/Version";
import Faq from "../Home/Faq";
import Partner from "../Home/Partner";
import { BlockLast, HomeStyle, HomeWrapper, TokenNoMicWrapper } from "./styled";
import "aos/dist/aos.css";
import AOS from "aos";
import { useContext, useEffect } from "react";
import AMFITeam from "../../components/AMFITeam";
import { LinearGardientLayer } from "../../Layout/styled";
import { ContextProviderWrapper } from "../../components/Context";
import Innovation from "../Home/Innovation";

export default function HomePhase2() {
  useEffect(() => {
    AOS.init();
  }, []);

  const { isMobile } = useContext(ContextProviderWrapper)!;

  return (
    <HomeWrapper>
      <BackgroundBanner>
        {/* <LinearGardientLayer
          left="unset"
          right="-700px"
          top="-440px"
          bottom="unset"
          width="1048px"
          height="1110px"
          layer_1={true}
          layer_2={false}
        /> */}
        <div className="bg-1">
          <div className="bg-2">
            <Banner />
          </div>
        </div>
        {/* <LinearGardientLayer
          left="-700px"
          right="unset"
          top="unset"
          bottom="-80px"
          width="1048px"
          height="1110px"
          layer_1={true}
          layer_2={isMobile ? false : true}
        /> */}
      </BackgroundBanner>
      <HomeStyle>
        <VersionAndMission />
        <Partner />
      </HomeStyle>
      <ExpressYourLimitation />
      <Ecosystem />
      <TokenNoMicWrapper>
        <AMFIToken />
        <ClearlyRoadmap />
      </TokenNoMicWrapper>
      <BlockLast>
        <Innovation />
        <Faq />
      </BlockLast>
    </HomeWrapper>
  );
}
