import styled from "styled-components";
import { StarUpContainer } from "../StarUp/styled";
import { BorderAnimation } from "../../Layout/styled";

export const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  max-width: 292px;
  width: 100%;
  z-index: 501;
  transition: all 0.25s ease-in;
  &.collapse {
    max-width: 100px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1221px) {
    max-width: 220px;
  }
  @media screen and (max-width: 1023px) {
    max-width: 100%;
    transform: translateY(-100%);
    &.open {
      transform: translateY(80px);
    }
  }
`;
export const SidebarCollapse = styled.div`
  position: absolute;
  right: -30px;
  top: 60px;
  width: 64px;
  height: 64px;
  cursor: pointer;
  z-index: 5;
  img {
    width: 100%;
  }
`;
export const SidebarWrapper = styled.div`
  height: 100vh;
  padding: 24px;
  border-right: 1px solid #344054;
  background: #040409;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.45);
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1023px) {
    height: calc(100vh - 80px);
    padding: 0;
  }
  @media screen and (min-width: 1024px) and (max-width: 1375px) {
    padding-right: 10px;
    padding-left: 10px;
    &.collapse {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
`;
export const SidebarLogo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #27282c;
  padding-bottom: 15px;
  &.collapse {
    img {
      transition: all 1s;
      &.normal {
        opacity: 0;
        visibility: hidden;
        width: 0;
      }
      &.active {
        width: 50px;
        height: 50px;
        visibility: visible;
        opacity: 1;
      }
    }
    a {
      height: 50px;
    }
  }
  a {
    position: relative;
    width: 100%;
    height: 33px;
    display: block;
  }
  img {
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translateY(-50%);
    &.normal {
      max-width: 156px;
      width: 100%;
    }
    &.active {
      opacity: 0;
      visibility: hidden;
    }
  }
  @media screen and (max-width: 1023px) {
    padding-bottom: 0;
    border: none;
    height: auto;
    img {
      position: relative;
      transform: unset;
      top: 0;
    }
    a {
      padding-top: 5px;
      max-width: 150px;
      min-height: auto;
    }
  }
`;
export const NavIcon = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    &.normal {
      opacity: 1;
    }
    &.active {
      opacity: 0;
    }
  }
`;

export const SidebarLinks = styled.ul`
  & > li {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    ${StarUpContainer},
    ${BorderAnimation} {
      opacity: 0;
      visibility: hidden;
      }
    &:hover {
      a {
        color: #fff;
        border-color: #212128;
        background: radial-gradient(79.19% 79.19% at 49.22% 92.12%, #37373C 0%, #000 100%);
        &::before {
          height: 100%;
        }
        ${StarUpContainer},
        ${BorderAnimation} {
        opacity: 1;
        visibility: visible;
      }
        ${NavIcon} {
          img {
            &.normal {
              opacity: 0;
            }
            &.active {
              opacity: 1;
            }
          }
        }
      }
    }
    a {
      position: relative;
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 15px;
      font-size: 16px;
      font-weight: 500;
      color: #7a7a7a;
      transition: all 0.3s ease;
      border: 1px solid transparent;
      border-radius: 12px;
      p {
        transition: all 0.2s ease-out;
        opacity: 1;
        visibility: visible;
        &.collapse {
          opacity: 0;
          visibility: hidden;
          font-size: 0;
        }
      }
      &.active {
        color: #fff;
        border-radius: 12px;
        border: 1px solid #212128;
        background: radial-gradient(79.19% 79.19% at 49.22% 92.12%, #37373C 0%, #000 100%);
        ${StarUpContainer},
        ${BorderAnimation} {
        opacity: 1;
        visibility: visible;
        }
        ${NavIcon} {
          img {
            &.normal {
              opacity: 0;
            }
            &.active {
              opacity: 1;
            }
          }
        }
      }
    }
  }
`;
export const SidebarContent = styled.div`
  overflow: hidden auto;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  @media screen and (max-width: 1023px) {
    height: calc(100% - 20px);
    gap: 20px;
    padding: 20px 20px 80px;
  }
`;
