import { styled } from "styled-components";

export const ClearlyRoadmapStyle = styled.div`
  position: relative;
  padding: 100px 80px;
  @media screen and (max-width: 1023px) {
    padding: 100px 0 60px;
  }
  .gif-for-ai-metafi {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding-bottom: 10px;
    .gif {
      width: 90px;
      height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .title-amfi-token {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      margin-bottom: 20px;
    }
    p {
      color: #a0aee5;
      text-align: center;
      font-family: Inter;
      font-size: 12.061px;
      font-style: normal;
      font-weight: 400;
      line-height: 20.405px; /* 169.183% */
      letter-spacing: 0.268px;
    }
  }
  .list-card {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    .card-item {
      position: relative;
      padding: 22px;
      width: calc(100% / 2 - 50px);
      border-radius: 20px;
      transition: all .3s;
      display: flex;
      align-items: flex-start;
      .icon {
        margin-right: 12px;
      }
      .image {
        width: 22px;
        margin-bottom: 20px;
        img {
          width: 100%;
        }
      }
      h3 {
        color: #FFF;
        font-family: Poppins;
        font-size: 27.563px;
        font-weight: 500;
      }
      p {
        color: #989898;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        padding-left: 10px;
        &:before {
          position: absolute;
          content: "";
          top: 8px;
          left: 0;
          width: 3px;
          height: 3px;
          background-color: #989898;
          border-radius: 20px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      gap: 16px;
      align-items: unset;
      padding: 0 10px;
      justify-content: space-between;
      .card-item {
        padding: 15px;
        width: calc(100% / 2 - 10px);
        h3 {
          font-size: 14px;
        }
        p {
          font-size: 10px !important;
          max-width: 220px;
          letter-spacing: normal;
        }
        height: auto;
        /* &:nth-child(1),
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5) {
          width: calc(50% - 10px);
          word-break: break-word;
        } */
        /* &:nth-child(3),
        &:nth-child(6) {
          width: 100%;
        } */
        .icon {
          width: 24px;
          flex-shrink: 0;
          position: relative;
          top: 2px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .button-Enhanced {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 75px;
  }
`;

export const ImageLeftStyle = styled.div`
  position: absolute;
  top: -660px;
  left: -870px;
  transition: all 0.5s ease-in-out;
  animation: upDownAndRotate 20s infinite linear;
  @keyframes upDownAndRotate {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(50px) rotate(180deg);
    }
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const ImageRightStyle = styled.div`
  position: absolute;
  right: -568px;
  top: -530px;
  opacity: 0.8;
  pointer-events: none;
`;

export const ImageRightV2Style = styled.div`
  position: absolute;
  right: -450px;
  top: -400px;
  animation: upAndDown 5s infinite linear;
  pointer-events: none;
  @keyframes upAndDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(50px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
