import { Dispatch, createSlice } from "@reduxjs/toolkit";

interface DriverState {
  userProfile: any;
  tokenPrice: any;
  investedTransaction: any;
  isLoading: any;
  isOpenModal: any;
}

const initialState: DriverState = {
  userProfile: null,
  tokenPrice: 0,
  investedTransaction: [],
  isLoading: false,
  isOpenModal: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserProfile(state, action) {
      state.userProfile = action.payload;
    },
    getTokenPrice(state, action) {
      state.tokenPrice = action.payload;
    },
    getInvestedTransaction(state, action) {
      state.investedTransaction = action.payload;
    },
    isLoadingSuccess(state,action) {
      state.isLoading = action.payload;
    },
    isOpenModalSuccess(state, action) {
      state.isOpenModal = action.payload;
    }
  },
});

export function UserOverview(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.getUserProfile(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function IsLoadingRedux(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.isLoadingSuccess(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function OpenModal(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.isOpenModalSuccess(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getTokenPrice(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.getTokenPrice(data));
    } catch (error) {
      console.log(error);
    }
  };
}

const userReducer = userSlice.reducer;

export default userReducer;
