import SelectCommon from "../Common/Select";
import vn from "../../assets/Flags/vietnam.svg";
import en from "../../assets/Flags/united.svg";
import hindi from "../../assets/Flags/hindi.svg";
import dubai from "../../assets/Flags/dubai.svg";
import thailand from "../../assets/Flags/thailand.svg";
import japan from "../../assets/Flags/japan.svg";
import china from "../../assets/Flags/china.svg";
import france from "../../assets/Flags/france.svg";
import korean from "../../assets/Flags/korean.svg";
import portuguese from "../../assets/Flags/portugal.svg";
import philipine from "../../assets/Flags/philippines.svg";
import malaysia from "../../assets/Flags/malaysia.svg";
import cambodia from "../../assets/Flags/cambodia.svg";
import indonesia from "../../assets/Flags/indonesia.svg";
import africa from "../../assets/Flags/africa.svg";
import russia from "../../assets/Flags/russia.svg";
import { ContextProviderWrapper } from "../Context";
import { useContext, useEffect } from "react";

const ChangeLanguage = () => {
  const { changeLanguage } = useContext(ContextProviderWrapper)!;
  // change languages
  const selectData = [
    { title: "English", img: en },
    { title: "Vietnamese", img: vn },
    { title: "Chinese", img: china },
    { title: "French", img: france },
    { title: "Hindi", img: hindi },
    { title: "Korean", img: korean },
    { title: "Portuguese", img: portuguese },
    { title: "Russian", img: russia },
    { title: "Malaysia", img: malaysia },
    { title: "Indonesia", img: indonesia },
    { title: "Philipines", img: philipine },
    { title: "Africa", img: africa },
    { title: "Cambodia", img: cambodia },
    { title: "Japanese", img: japan },
    { title: "Dubai", img: dubai },
    { title: "Thailand", img: thailand },
  ];

  const handleChangeLanguage = (data: any) => {
    localStorage.setItem("languageSelected", data);
    switch (data) {
      case "English":
        changeLanguage("en");
        return localStorage.setItem("languageState", "en");
      case "Vietnamese":
        changeLanguage("vi");
        return localStorage.setItem("languageState", "vi");
      case "Chinese":
        changeLanguage("china");
        return localStorage.setItem("languageState", "china");
      case "French":
        changeLanguage("french");
        return localStorage.setItem("languageState", "french");
      case "Hindi":
        changeLanguage("hindi");
        return localStorage.setItem("languageState", "hindi");
      case "Korean":
        changeLanguage("korean");
        return localStorage.setItem("languageState", "korean");
      case "Portuguese":
        changeLanguage("portuguese");
        return localStorage.setItem("languageState", "portuguese");
      case "Russian":
        changeLanguage("russian");
        return localStorage.setItem("languageState", "russian");
      case "Malaysia":
        changeLanguage("malaysia");
        return localStorage.setItem("languageState", "malaysia");
      case "Indonesia":
        changeLanguage("indonesia");
        return localStorage.setItem("languageState", "indonesia");
      case "Philipines":
        changeLanguage("philipines");
        return localStorage.setItem("languageState", "philipines");
      case "Africa":
        changeLanguage("africa");
        return localStorage.setItem("languageState", "africa");
      case "Cambodia":
        changeLanguage("cambodia");
        return localStorage.setItem("languageState", "cambodia");
      case "Japanese":
        changeLanguage("japanese");
        return localStorage.setItem("languageState", "japanese");
      case "Dubai":
        changeLanguage("dubai");
        return localStorage.setItem("languageState", "dubai");
      case "Thailand":
        changeLanguage("thailand");
        return localStorage.setItem("languageState", "thailand");
      default:
        changeLanguage("en");
        return localStorage.setItem("languageState", "en");
    }
  };

  useEffect(() => {
    handleChangeLanguage(localStorage.getItem("languageSelected"));
  }, []);

  return (
    <></>
    // <SelectCommon
    //   data={selectData}
    //   defaultValue={
    //     localStorage.getItem("languageSelected") !== "null"
    //       ? localStorage.getItem("languageSelected")
    //       : selectData[0].title
    //   }
    //   onChange={handleChangeLanguage}
    // />
  );
};

export default ChangeLanguage;
