import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { AuthTitle, LoginContainer, TextForgot } from "./styled";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import {
  AuthBlock,
  AuthForm,
  AuthLogo,
  ErrorState,
} from "../../../Layout/Auth/styled";
import { ButtonStyle2 } from "../../Homev2/styled";
import StarUp from "../../../components/StarUp";
import { BorderAnimation, CheckBoxContainer } from "../../../Layout/styled";
import auth_logo from "../../../assets/Auth/form_icon.png";
import InputCommon from "../../../components/Common/Input";
import { gql, useMutation } from "@apollo/client";
import { Checkbox, CheckboxProps } from "antd";
import { useState } from "react";
import LoadingIcon from "../../../components/LoadingIcon";
import { useTranslation } from "react-i18next";

type FormValuesProps = {
  email: string;
  password: string;
};

const CHECK_2FA = gql`
  mutation {
    check2FA
  }
`;

const Login = () => {
  const { t } = useTranslation();
  const [check2FA] = useMutation(CHECK_2FA);
  const navigate = useNavigate();
  const schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password should be at least 6 characters"),
  });

  const rememberDefaultValue = localStorage.getItem("RemMe");

  const defaultValues = {
    email: rememberDefaultValue
      ? rememberDefaultValue && JSON.parse(rememberDefaultValue)[0].email
      : "",
    password: rememberDefaultValue
      ? rememberDefaultValue && JSON.parse(rememberDefaultValue)[0].password
      : "",
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  // Submit
  const [loading, setLoading] = useState(false);
  const auth: any = getAuth();
  const onSubmit: SubmitHandler<FormValuesProps> = async (data: any) => {
    setLoading(true);
    try {
      const userCredential: any = await signInWithEmailAndPassword(
        auth,
        data.email.trim(),
        data.password.trim()
      );
      const idToken = await auth.currentUser.getIdToken(true);
      const res: any = await check2FA();
      if (res) {
        if (res && res.data && res.data.check2FA) {
          setLoading(false);
          return navigate("/auth/login-2fa");
        }
        if (!userCredential.user.emailVerified) {
          setLoading(false);
          return navigate("/auth/verify");
        }
        if (!res.data.check2FA && userCredential.user.emailVerified) {
          localStorage.setItem("idToken", idToken);
          setLoading(false);
          return navigate("/dashboard");
        }
      }

      // Check remember me
      const remMeInfo = {
        email: data.email.trim(),
        password: data.password.trim(),
      };
      if (valueCheckBox) {
        const remMeList = JSON.parse(localStorage.getItem("RemMe") || "[]");
        const existingAccountIndex = remMeList.findIndex(
          (account: any) => account.email === remMeInfo.email
        );
        if (existingAccountIndex === -1) {
          localStorage.setItem("RemMe", JSON.stringify([data]));
        } else {
          if (valueCheckBox) {
            remMeList[existingAccountIndex] = data;
            localStorage.setItem("RemMe", JSON.stringify(remMeList));
          }
        }
      }
    } catch (error: any) {
      setLoading(false);
      if (error.message === "Firebase: Error (auth/invalid-credential).") {
        toast.error("Please check your email or password");
      }
    }
  };

  // Remember me
  const [valueCheckBox, setValueCheckBox] = useState(false);
  const onChangeCheckBox: CheckboxProps["onChange"] = (e: any) => {
    setValueCheckBox(e.target.checked);
  };

  return (
    <LoginContainer>
      <AuthForm>
        <AuthLogo>
          <img width={60} height={60} src={auth_logo} alt="logo" loading="lazy" />
        </AuthLogo>
        <AuthTitle>
          <h2>Login to AISOLARS</h2>
          <p>From revolutionizing industries to redefining <br />
            possibilities, we're at the forefront of innovation.
          </p>
        </AuthTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
              }}
            >
              <AuthBlock>
                <div>
                  <label>{t("Email")}</label>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <div style={{ position: 'relative' }}>
                        <InputCommon
                          {...field}
                          status={errors.email ? "error" : ""}
                          name="email"
                          placeholder="Email"
                        />
                      </div>
                    )}
                  />
                </div>
                {errors.email && (
                  <ErrorState>{errors.email?.message}</ErrorState>
                )}
              </AuthBlock>
              <AuthBlock>
                <div>
                  <label>{t("Password")}</label>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <InputCommon
                        {...field}
                        password
                        status={errors.password ? "error" : ""}
                        name="password"
                        placeholder="Password"
                      />
                    )}
                  />
                </div>
                {errors.password && (
                  <ErrorState>{errors.password?.message}</ErrorState>
                )}
              </AuthBlock>
            </div>
            <CheckBoxContainer>
              <Checkbox onChange={onChangeCheckBox}>
                {t("Remember me")}
              </Checkbox>
            </CheckBoxContainer>
            <div
              style={{
                width: "100%",
                textAlign: "right",
              }}
            >
              <Link
                style={{
                  fontFamily: "DM Sans",
                  color: "#D3D3D3",
                  fontSize: "16px",
                }}
                to="/auth/reset"
              >
                {t("Forgot Password?")}
              </Link>
            </div>
          </div>
          <ButtonStyle2
            style={{
              pointerEvents: loading ? "none" : "auto",
            }}
          >
            <BorderAnimation />
            <button type="submit">
              <span>
                {t("Sign In")}
                {loading && <LoadingIcon />}
              </span>
            </button>
          </ButtonStyle2>
        </form>
        <TextForgot>
          {t("Do not have an account?")}{" "}
          <Link to="/auth/register">{t("Create Account")}</Link>
        </TextForgot>
      </AuthForm>
    </LoginContainer>
  );
};

export default Login;
