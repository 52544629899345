import { useInView, useMotionValueEvent, useScroll } from "framer-motion";
import { AnimatedText } from "../TextAnimation";
import {
  ChallengeBlockInner,
  ChallengeContainer,
  ExpressBody,
  ExpressWrapper,
  ExpressYourLimitationStyle,
} from "./style";
import { useContext, useRef, useState } from "react";
import { ContextProviderWrapper } from "../Context";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

export default function ExpressYourLimitation() {
  const { t } = useTranslation();
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const ref = useRef(null);
  const isInView = useInView(ref);
  const { scrollYProgress } = useScroll({
    target: ref,
  });
  const [stateControl, setStateControl] = useState(0);
  const [scrollProgressState, setScrollProgressState] = useState(0)
  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    const convertValue: any = Number(latest.toFixed(2));
    if (isInView && isDesktop) {
      setScrollProgressState(convertValue)
      if (convertValue > 0 && convertValue < 0.23) {
        setStateControl(1);
      } else if (convertValue > 0.24 && convertValue < 0.49) {
        setStateControl(2);
      } else if (convertValue > 0.5 && convertValue < 0.74) {
        setStateControl(3);
      } else if (convertValue > 0.75 && convertValue < 1) {
        setStateControl(4);
      } else if (convertValue === 1) {
        setStateControl(5);
      }
    }
  });

  return (
    <ExpressYourLimitationStyle convertValue={isDesktop && scrollProgressState} ref={ref}>
      <ExpressWrapper>
        <AnimatedText
          el="p"
          text={
            'Challenge your limits\n with AI!'
          }
        />
        <p>
          {t(
            "Receive comprehensive AI support for all your DeFi activities, like a trusted companion. Experience, explore, and enjoy the future of decentralized finance with ease and confidence"
          )}{" "}
        </p>
        <ExpressBody className="container-ai">
          {isDesktop ? <ChallengeContainer>
            {dataChallenge.map((item, index) => {
              return (
                <ChallengeBlockInner style={{
                  opacity: stateControl < item.id + 1 ? "1" : "0"
                }} key={index}
                >
                  <div className="block-left">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    <Link to={item.link}>{item.textLink}</Link>
                  </div>
                  <div className="content-right">
                    <img width={483} height={283} loading="lazy" src={item.img} alt="img" />
                  </div>
                </ChallengeBlockInner>
              )
            })}
          </ChallengeContainer> : <ChallengeContainer>
            <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
              {dataChallenge.map((item, index) => {
                return (
                  <SwiperSlide>
                    <ChallengeBlockInner key={index}
                    >
                      <div className="block-left">
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                        <Link to={item.link}>{item.textLink}</Link>
                      </div>
                      <div className="content-right">
                        <img width={483} height={283} loading="lazy" src={item.img} alt="img" />
                      </div>
                    </ChallengeBlockInner>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </ChallengeContainer>}
        </ExpressBody>
      </ExpressWrapper>
    </ExpressYourLimitationStyle>
  );
}

const dataChallenge = [
  {
    id: 1,
    title: "AI text chat",
    description: "Designed to enhance communication and user engagement, our AI-powered text chat provides seamless interaction and support, making your digital experience more intuitive and responsive",
    link: "#",
    textLink: "Discover",
    img: "/assets/images/Challenge/img_01.png"
  },
  {
    id: 2,
    title: "AI image generate",
    description: "Leveraging advanced AI technology, this feature enables high-quality image production, allowing you to unleash your creativity with ease and produce captivating visual content",
    link: "#",
    textLink: "Discover",
    img: "/assets/images/Challenge/img_02.png"
  },
  {
    id: 3,
    title: "AI bot trading",
    description: "Our AI algorithms analyze market trends and execute trades on your behalf, enhancing your trading decisions and automating your trading experience for better results",
    link: "#",
    textLink: "Discover",
    img: "/assets/images/Challenge/img_03.png"
  },
  {
    id: 4,
    title: "AI voice",
    description: "This feature is designed to elevate voice creation by utilizing realistic text-to-speech, enabling diverse tones for creating ideal AI voiceovers.",
    link: "#",
    textLink: "Discover",
    img: "/assets/images/Challenge/img_04.png"
  },
  {
    id: 5,
    title: "AI video",
    description: "Simplify video creation and boost your creativity, producing engaging videos that captivate your audience and convey content effectively",
    link: "#",
    textLink: "Discover",
    img: "/assets/images/Challenge/img_05.png"
  },
]
