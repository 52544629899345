import { Dropdown, MenuProps } from "antd";
import { ProfileIcon, ProfileInfo } from "../../Header/Dashboard/styled";
import { ProfileContainer } from "./styled";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import { ContextProviderWrapper } from "../../Context";
import { useContext, useEffect, useState } from "react";
import dumb_avt from "../../../assets/Header/dumb_avt.png";
import { useTranslation } from "react-i18next";

const ProfileCommon = ({ reverse, user }: any) => {
  const { t } = useTranslation();
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const itemsMobile: MenuProps["items"] = [
    {
      label: (
        <p>
          {!isDesktop && (
            <>
              {user?.email.split("@gmail.com")}
              <br />
              {user?.email}
            </>
          )}
        </p>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: <Link to="/account">{t("Settings")}</Link>,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: <p onClick={() => handleLogout()}>{t("Logout")}</p>,
      key: "2",
    },
  ];

  const itemsDesktop: MenuProps["items"] = [
    {
      label: <Link to="/account">{t("Settings")}</Link>,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: <p onClick={() => handleLogout()}>{t("Logout")}</p>,
      key: "2",
    },
  ];

  // Handle logout
  const navigate = useNavigate();
  const auth = getAuth();
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/auth/login");
      localStorage.removeItem("idToken");
    } catch (error) {
      console.error("Error when logout:", error);
    }
  };

  const [openProfile, setOpenProfile] = useState(false);

  useEffect(() => {
    if (openProfile) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [openProfile]);

  return (
    <ProfileContainer
      style={{
        flexDirection: reverse ? "row-reverse" : "unset",
      }}
    >
      <Dropdown
        menu={isDesktop ? { items: itemsDesktop } : { items: itemsMobile }}
        trigger={["click"]}
        onOpenChange={(open) => {
          setOpenProfile(open);
        }}
      >
        <div
          style={{ display: "flex", gap: "10px", cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {isDesktop && (
            <ProfileInfo>
              <p>{user?.email.split("@gmail.com")}</p>
              <span>{user?.email}</span>
              <span>ID: {user?.accountID}</span>
            </ProfileInfo>
          )}
          <ProfileIcon>
            <img src={dumb_avt} alt="avt" />
          </ProfileIcon>
        </div>
      </Dropdown>
    </ProfileContainer>
  );
};

export default ProfileCommon;
