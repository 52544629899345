import { styled } from "styled-components";
import { HeaderLogo } from "../Header/styled";
import { HeaderLanguage } from "../ChooseLanguage/styled";
import { SelectContainer } from "../Common/Select/styled";

export const HeaderStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 12px 0px;
  background: rgba(3, 5, 21, 0.11);
  backdrop-filter: blur(25px);
  transition: all 0.3s;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    gap: 20px;
    .navbar-desktop {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 20px;
      transition: all 0.3s;
    }
    .header-content {
      display: flex;
      padding: 16px;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      gap: 30px;
      border-radius: 54px;
      background: rgba(255, 255, 255, 0);
      li {
        list-style: none;
        a {
          font-size: 14px;
          font-weight: 400;
          line-height: 17.384px;
          letter-spacing: 0.29px;
          color: #ebefff;
        }
      }
    }
    .dot-link {
      width: 3.622px;
      height: 3.622px;
      border-radius: 50%;
      background: rgba(161, 174, 229, 0.7);
      margin: 0 5px;
    }

    .btn-staking {
      button {
        border-radius: 10px;
        border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.4));
        background: var(--Surface-Background, rgba(40, 40, 40, 0.7));
        background-blend-mode: luminosity;
        box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
        backdrop-filter: blur(50px);
        padding: 16px 27px;
        cursor: pointer;
        span {
          color: var(--Text-Secondary, rgba(248, 248, 248, 0.70));
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          &:nth-child(1) {
            color: #ebefff;
          }
          &:nth-child(3) {
            color: rgba(161, 174, 229, 0.7);
            padding-right: 5px;
          }
        }
      }
    }
  }
  ${HeaderLanguage} {
    margin-right: 0;
    .ant-select-dropdown {
      position: fixed;
      top: 60px !important;
      bottom: unset !important;
    }
    ${SelectContainer} {
      width: 110px;
    }
    @media screen and (max-width: 1023px) {
      margin: 0;
    }
    @media screen and (max-width: 767px) {
      width: auto;
      padding: 0;
      ${SelectContainer} {
        width: 60px;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    ${HeaderLogo} {
      width: 110px;
    }
  }
  @media screen and (max-width: 1023px) {
    ${HeaderLogo} {
      flex-shrink: unset;
    }
    .container {
      position: relative;
      justify-content: space-between;
      padding: 0 10px;
      gap: 10px;
      .navbar-desktop {
        position: fixed;
        flex-direction: column;
        width: 100%;
        left: 0;
        top: 0;
        transform: translateY(0);
        transition: all 0.3s;
        background-color: rgb(0 2 3 / 95%);
        backdrop-filter: blur(12px);
        .header-content {
          position: relative;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          width: 100%;
          height: calc(100vh - 50px);
          padding: 20px 20px;
          border-radius: 0;
          transition: all 0.3s;
          border: none;
          li {
            width: 100%;
            transition: all 0.5s ease;
            a {
              display: block;
              width: 100%;
              padding: 20px 15px;
              border: 1px solid transparent;
              border-radius: 10px;
              font-size: 16px;
              transition: all 0.3s;
              cursor: pointer;
              &.active {
                background-color: rgb(173, 188, 240, 0.07);
                border-color: rgb(161, 174, 229, 0.21);
              }
            }
            &:hover {
              a {
                background-color: rgb(173, 188, 240, 0.07);
                border-color: rgb(161, 174, 229, 0.21);
              }
            }
          }
          .dot-link {
            display: none;
          }
        }
      }
      .btn-staking {
        margin-left: auto;
        button {
          height: 40px;
          padding: 10px;
        }
        span {
          font-size: 12px !important;
        }
      }
    }
  }
`;
export const HeaderNavigation = styled.div`
  width: 100%;
  ul {
    width: max-content;
  }
  @media screen and (max-width: 1023px) {
    ${HeaderLanguage} {
      padding: 20px 20px 0;
    }
  }
`;
