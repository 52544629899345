import styled from "styled-components";
import auth_bg from "../../assets/Auth/auth_bg.jpg";
import auth_form_bg from "../../assets/Auth/auth_form_bg.png";
import { InputContainer } from "../../components/Common/Input/styled";
import { ButtonStyle } from "../../page/Homev2/styled";
import shape_1 from "../../assets/Auth/shape_1.png";
import shape_2 from "../../assets/Auth/shape_2.png";
import { BorderAnimation } from "../styled";

export const AuthContainer = styled.div`
  overflow: hidden;
  ${ButtonStyle} {
    ${BorderAnimation} {
      &::before {
        border-radius: 50px;
      }
    }
  }
`;
export const AuthWrapper = styled.div`
  position: relative;
  padding: 80px 0;
  margin: 0 auto;
  min-height: 100vh;
  background: url(${auth_bg}) no-repeat center / 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 767px) {
    padding: 120px 10px 80px;
    min-height: auto;
  }
`;
export const AuthAnimation = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  span {
    position: absolute;
    background: no-repeat center / 100% auto;
    transition: all 0.5s ease-in-out;
    &.register {
      animation-name: registerShape;
      animation-duration: 8s;
    }
    &.reset:nth-child(2) {
      animation-name: resetShape;
      animation-duration: 8s;
    }
  }
`;
export const AuthForm = styled.div`
  position: relative;
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  ${InputContainer} {
    border-radius: 12px;
    border: 1px solid rgba(172, 172, 172, 0.33);
    background: linear-gradient(0deg, var(--neutral-neutral-420, rgba(18, 18, 18, 0.20)) 0%, var(--neutral-neutral-420, rgba(18, 18, 18, 0.20)) 100%), var(--neutral-neutral-310, rgba(40, 40, 40, 0.10));
    backdrop-filter: blur(23px);
  }
  ${ButtonStyle} {
    button {
      width: 100%;
      min-height: 50px;
      border-radius: 50px;
      background: rgba(255, 255, 255, 0);
      box-shadow: 0px 0px 18.108px 0px rgba(255, 255, 255, 0.06) inset;
      span {
        font-size: 14px;
      }
    }
  }
`;
export const AuthLogo = styled.div`
  width: 60px;
  margin: 0 auto 20px;
  img {
    height: auto;
    width: 100%;
  }
`;
export const AuthBlock = styled.div`
  label {
    display: block;
    margin-bottom: 5px;
    color: #fff;
    font-size: 13px;
    span {
      color: #d90c0c;
    }
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
export const ErrorState = styled.p`
  margin-top: 10px;
  color: #d90c0c;
  font-size: 14px;
`;
