import { useEffect, useState } from "react";
import {
  AMFIImgToken,
  AMFIMListToken,
  AMFITokenStyle,
  ContentCenterChart,
  ContentChart,
} from "./styled";
import { AnimatedText } from "../TextAnimation";
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import { shortenAddress } from "../../utils/addressUser";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

export default function AMFIToken() {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [numberChart, setNumberChart] = useState("144,000,000");

  useEffect(() => {
    if (openModal) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [openModal]);

  const handleChartClick = (e:any, chartContext:any, config:any) => {
    const dataPointIndex = config.dataPointIndex;
    const value = config.w.globals.series[dataPointIndex];
    if(value === 12) {
      setNumberChart('144,000,000')
    } else if (value === 6) {
      setNumberChart('72,000,000')
    } else if (value === 15) {
      setNumberChart('180,000,000')
    } else if (value === 3) {
      setNumberChart('36,000,000')
    } else if (value === 36) {
      setNumberChart('432,000,000')
    } else if (value === 18) {
      setNumberChart('216,000,000')
    } else {
      setNumberChart('120,000,000')
    }
  }

  //chart
  const [series] = useState<number[]>([12, 6, 15, 3, 36, 18, 10]);
  const [options] = useState<any>({
    chart: {
      type: "donut",
      events: {
        dataPointSelection: handleChartClick,
      },
    },
    labels: [
      "Public Sale",
      "Team & Advisor",
      "Liquidity",
      "Airdrop",
      "Staking",
      "Ecosystem",
      "Marketing",
    ],
    fill: {
      colors: [
        "#FF8A00",
        "#863CFF",
        "#33B1FE",
        "#007D79",
        "#FF7DB6",
        "#F94D5B",
        "#DE0000",
      ],
    },
    tooltip: {
      y: {
        formatter: (val: number) => `${val}%`,
      },
    },
    legend: {
      markers: {
        fillColors: [
          "#FF8A00",
          "#863CFF",
          "#33B1FE",
          "#007D79",
          "#FF7DB6",
          "#F94D5B",
          "#DE0000",
        ],
      },
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: true,
    },
    responsive: [
      {
        breakpoint: 767,
        options: {
          legend: {
            show: false,
          },
          label: {
            show: false,
          },
          dataLabels: {
            enabled: true,
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        expandOnClick: false,
        // donut: {
        //   labels: {
        //     show: true,
        //   },
        // },
      },
    },
  });

  const listToken = [
    {
      textSmall: "Token’s Name",
      textBig: "AISOLARS",
    },
    {
      textSmall: "Symbol",
      textBig: "AISOL",
    },
    {
      textSmall: "Total Supply",
      textBig: "1,200,000,000",
    },
    {
      textSmall: "Decimal",
      textBig: "18",
    },
    {
      textSmall: "Network",
      textBig: "BSC",
    },
    {
      wallet: true,
      textSmall: "Smart Contract",
      textBig: `${shortenAddress(
        "0x9024C035f0281465Ff1635a45B3cf75dA144D405"
      )}`,
    },
  ];

  return (
    <>
      <AMFITokenStyle id="tokenomic">
        <AMFIImgToken>
          <img src="/assets/images/TokenNomic/icn.png" alt="" />
        </AMFIImgToken>
        <div className="title-amfi-token">
          <AnimatedText
            data-aos="fade-up"
            el="h2"
            text={t("The AISOLARS Token")}
          />
          <p data-aos="fade-up">{t("Unleash your creative potential.")}</p>
          <p data-aos="fade-up">
            {t(
              "Create, share, trade, shop and thrive in the AISOLARS ecosystem"
            )}
          </p>
        </div>
        <ContentChart>
          <ContentCenterChart>
            <p>{numberChart}</p>
            <figure>
              <img
                src="./assets/images/TokenNomic/chart_token.png"
                alt="token"
              />
            </figure>
          </ContentCenterChart>
          <ReactApexChart
            height={344}
            className="chart"
            options={options}
            series={series}
            type="donut"
          />
        </ContentChart>
        <AMFIMListToken>
          {listToken.map((item: any, index: number) => (
            <li key={index} className={item.wallet && "wallet"}>
              {item.wallet ? (
                <>
                  <Link
                    to="https://bscscan.com/address/0x9024C035f0281465Ff1635a45B3cf75dA144D405"
                    target="_blank"
                  >
                    <span>{item.textSmall}</span>
                    <h3>{item.textBig}</h3>
                  </Link>
                  {item.wallet && (
                    <div className="copy">
                      <CopyToClipboard
                        text={"0x9024C035f0281465Ff1635a45B3cf75dA144D405"}
                        onCopy={() => toast.success("Coppied !")}
                      >
                        <img src="/assets/icons/icn_copy.svg" alt="" />
                      </CopyToClipboard>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <span>{item.textSmall}</span>
                  <h3>{item.textBig}</h3>
                </>
              )}
            </li>
          ))}
        </AMFIMListToken>
      </AMFITokenStyle>
    </>
  );
}
