import { Outlet, useLocation } from "react-router-dom";
import { AuthAnimation, AuthContainer, AuthWrapper } from "./styled";
import Footer from "../../components/Footer";
import HeaderPhase2 from "../../components/HeaderPhase2";

const AuthLayout = () => {
  const { pathname } = useLocation();
  return (
    <AuthContainer>
      <HeaderPhase2 />
      <AuthWrapper>
        <AuthAnimation>
          <span
            className={
              pathname === "/auth/register"
                ? "register"
                : pathname === "/auth/reset"
                ? "reset"
                : ""
            }
          ></span>
          <span
            className={
              pathname === "/auth/register"
                ? "register"
                : pathname === "/auth/reset"
                ? "reset"
                : ""
            }
          ></span>
        </AuthAnimation>
        <Outlet />
      </AuthWrapper>
      <Footer />
    </AuthContainer>
  );
};

export default AuthLayout;
