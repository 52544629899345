import { gql, useMutation } from "@apollo/client";
import { getAuth } from "firebase/auth";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ResetContainer } from "./styled";
import {
  AuthBlock,
  AuthForm,
  AuthLogo,
  ErrorState,
} from "../../../Layout/Auth/styled";
import auth_logo from "../../../assets/Auth/form_icon.png";
import { BorderAnimation } from "../../../Layout/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import InputCommon from "../../../components/Common/Input";
import ReCAPTCHA from "react-google-recaptcha";
import { ButtonStyle, ButtonStyle2 } from "../../Homev2/styled";
import StarUp from "../../../components/StarUp";
import { AuthTitle, TextForgot } from "../Login/styled";
import { NavLink } from "react-router-dom";
import LoadingIcon from "../../../components/LoadingIcon";
import { useTranslation } from "react-i18next";

const SEND_EMAIL_2FA = gql`
  mutation {
    sendEmail2FA
  }
`;

const RESET_2FA = gql`
  mutation reset2FA($code: String!, $token: String!) {
    reset2FA(code: $code, token: $token)
  }
`;

type FormValuesProps = {
  code: string;
};

const Reset2FA = () => {
  const { t } = useTranslation();
  const auth: any = getAuth();
  const [countdown, setCountdown] = useState(-1);
  const [sendEmail2FA] = useMutation(SEND_EMAIL_2FA);
  const [reset2FA] = useMutation(RESET_2FA);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const onRecaptchaChange = (token: any) => {
    setToken(token);
  };

  const defaultValues = useMemo(
    () => ({
      code: "",
    }),
    []
  );

  const schema = yup.object().shape({
    code: yup.string().required("Code 2FA is required"),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (countdown > 0) {
      const intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [countdown]);

  // Handle send OTP Email
  const handleSendOTP = async () => {
    setCountdown(30);
    try {
      await sendEmail2FA();
      toast.success("Please check your email");
    } catch (error: any) {
      toast.error("Email sent fail");
    }
  };

  // Handle reset 2FA
  const [loading, setLoading] = useState(false);
  const handleReset2FA = async (data: any) => {
    try {
      if (!token) {
        setLoading(false);
        return toast.error("Please enter captcha!");
      }
      await reset2FA({
        variables: {
          token,
          code: data.code,
        },
      });
      setLoading(true);
      navigate("/account");
    } catch (err: any) {
      setLoading(false);
      console.log("[err]", err);
      toast.error(err.message);
    }
  };

  // Submit form
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      handleReset2FA(data);
    } catch (error: any) {
      toast.error(error.messasge);
    }
  };

  return (
    <ResetContainer>
      <AuthForm>
        <AuthLogo>
          <img src={auth_logo} alt="logo" />
        </AuthLogo>
        <AuthTitle>
          <h2>Reset 2FA Authenticator</h2>
          <p>{t("Please check your email:")}<br />
            {auth && auth?.currentUser?.email}
          </p>
        </AuthTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
              }}
            >
              <AuthBlock>
                <div>
                  <label>
                    {t("Code OTP")}
                    <span>*</span>
                  </label>
                  <Controller
                    name="code"
                    control={control}
                    render={({ field }) => (
                      <InputCommon
                        {...field}
                        status={errors.code ? "error" : ""}
                        name="email"
                        placeHolder={`${t("Enter OTP")}`}
                      />
                    )}
                  />
                </div>
                {errors.code && <ErrorState>{errors.code?.message}</ErrorState>}
              </AuthBlock>
            </div>
            <ButtonStyle2
              onClick={() => {
                handleSendOTP();
              }}
              style={{
                marginBottom: "20px",
              }}
            >
              <BorderAnimation />
              <button type="button" disabled={countdown > 0}>
                {/* <StarUp /> */}
                <span>
                  {countdown > 0 ? `${countdown}s` : `${t("Send Email")}`}
                </span>
              </button>
            </ButtonStyle2>
            <ReCAPTCHA
              theme="dark"
              size="normal"
              sitekey="6LfXAf4pAAAAABYEoKRMEt4heLQF3SonFwvX7KwT"
              onChange={onRecaptchaChange}
            />
          </div>
          <ButtonStyle2
            style={{
              pointerEvents: loading ? "none" : "auto",
            }}
          >
            <BorderAnimation />
            <button type="submit">
              {/* <StarUp /> */}
              <span>
                {t("Reset 2FA")}
                {loading && <LoadingIcon />}
              </span>
            </button>
          </ButtonStyle2>
        </form>
        <TextForgot>
          {t("Already have an account?")}{" "}
          <NavLink to="/auth/login">{t("Sign In")}</NavLink>
        </TextForgot>
      </AuthForm>
    </ResetContainer>
  );
};

export default Reset2FA;
