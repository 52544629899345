import { ModalNotificationInner, ModalNotificationWrapper } from "./styled";

const ModalNotification = ({ closeFunction }: any) => {
  return (
    <ModalNotificationWrapper>
      <ModalNotificationInner>
        <div>
          <figure className="icon-close"
            onClick={closeFunction}
          >
            <img
              src="/assets/icons/icon_close.svg"
              alt="icon"
              loading="lazy"
            />
          </figure>
          <figure>
            <img src="/assets/icons/logo-v2.png" alt="icon" loading="lazy" />
          </figure>
          <h2>Notification</h2>
          <p>
            AISolars System updates and integrates games
            <br />
            Integration time from August 20, 2024 to August 25, 2024, While the
            AISolars system is updating to integrate games, we recommend that
            customers not deposit or withdraw during this time. We appreciate
            your cooperation.
          </p>
        </div>
      </ModalNotificationInner>
    </ModalNotificationWrapper>
  );
};

export default ModalNotification;
