import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationVI from './locales/translate/vi/translation.json'
import translationCHINA from './locales/translate/china/translation.json'
import translationRUSSIA from './locales/translate/russia/translation.json'
import translationFRENCH from './locales/translate/french/translation.json'
import translationPORTUGUESE from './locales/translate/portuguese/translation.json'
import translationJAPANESE from './locales/translate/japanese/translation.json'
import translationKOREAN from './locales/translate/korean/translation.json'
import translationCAMBODIA from './locales/translate/cambodia/translation.json'
import translationAFRICA from './locales/translate/africa/translation.json'
import translationPHILIPINES from './locales/translate/philipines/translation.json'
import translationDUBAI from './locales/translate/dubai/translation.json'
import translationMALAYSIA from './locales/translate/malaysia/translation.json'
import translationINDONESIA from './locales/translate/indonesia/translation.json'
import translationHINDI from './locales/translate/hindi/translation.json'
import translationTHAILAND from './locales/translate/thailand/translation.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    resources: {
      vi: {
        translations: translationVI,
      },
      china: {
        translations: translationCHINA,
      },
      french: {
        translations: translationFRENCH,
      },
      hindi: {
        translations: translationHINDI,
      },
      indonesia: {
        translations: translationINDONESIA,
      },
      korean: {
        translations: translationKOREAN,
      },
      malaysia: {
        translations: translationMALAYSIA,
      },
      philipines: {
        translations: translationPHILIPINES,
      },
      portuguese: {
        translations: translationPORTUGUESE,
      },
      russian: {
        translations: translationRUSSIA,
      },
      africa: {
        translations: translationAFRICA,
      },
      cambodia: {
        translations: translationCAMBODIA,
      },
      japanese: {
        translations: translationJAPANESE,
      },
      dubai: {
        translations: translationDUBAI,
      },
      thailand: {
        translations: translationTHAILAND,
      }
    },
    fallbackLng: "en",
    debug: false,

    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
