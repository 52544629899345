import React from "react";
import {
  InnovationContainer,
  InnovationLeft,
  InnovationRight,
  InnovationWrapper,
  InnovationWrapperInner,
} from "./styled";
import { AnimatedText } from "../../../components/TextAnimation";
import { useTranslation } from "react-i18next";
import { ButtonStyle } from "../../Homev2/styled";
import icnCircle from '../../../assets/Faq/img_circle.png'

const Innovation = () => {
  const { t } = useTranslation();
  return (
    <InnovationContainer>
      <InnovationWrapper>
        <InnovationWrapperInner>
          <InnovationLeft>
            <div className="title">
              <AnimatedText
                data-aos="fade-right"
                el="h2"
                text={t("Elevating Innovation \nBeyond Boundaries")}
              />
            </div>
            <p>
              Elevate your business to new heights with our cutting-edge
              artificial intelligence solutions deployed worldwide. Unlock your
              company's potential with advanced AI technologies.
            </p>
            <ButtonStyle data-aos="fade-right">
              <button>
                <span>{t("Contact us")}</span>
              </button>
            </ButtonStyle>
          </InnovationLeft>
          <InnovationRight>
            <img src={icnCircle} alt="" />
          </InnovationRight>
        </InnovationWrapperInner>
      </InnovationWrapper>
    </InnovationContainer>
  );
};

export default Innovation;
