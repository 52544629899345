import styled from "styled-components";
import { ButtonStyle } from "../../Homev2/styled";

export const InnovationContainer = styled.div``
export const InnovationWrapper = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
`
export const InnovationWrapperInner = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 19px;
  background: linear-gradient(180deg, #0E0E0E 0%, #0D0D0D 100%);
  padding: 39px;
  border: solid 1px #404040;
  border-radius: 19px;
  position: relative;
  min-height: 377px;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    display: block;
    padding-bottom: 330px;
  }
`
export const InnovationLeft = styled.div`
  max-width: 400px;
  .title {
    margin-bottom: 19px;
  }
  p {
    color: #F5F5F5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 127.778% */
    letter-spacing: -0.36px;
    margin-bottom: 32px;
  }
  ${ButtonStyle} {
    width: 128.436px;
    height: 41.018px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const InnovationRight = styled.div`
  position: absolute;
  bottom: -4px;
  right: 0;
  mix-blend-mode: luminosity;
`