import styled from "styled-components";
import { BorderAnimation } from "../../../Layout/styled";

export const InputContainer = styled.div`
  position: relative;
  ${BorderAnimation} {
    z-index: 5;
    visibility: hidden;
    &::before {
      opacity: 0;
      border-radius: 10px;
    }
  }
  &:hover,
  &:focus {
    ${BorderAnimation} {
      visibility: visible;
      opacity: 1;
    }
  }
  .ant-input-password {
    input {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      padding: 16px 50px 16px 24px;
    }
    .ant-input-suffix {
      position: absolute;
      top: 50%;
      margin-top: -8px;
      right: 24px;
    }
  }
  input,
  .ant-input-affix-wrapper {
    width: 100%;
    padding: 16px 24px;
    color: #fff;
    font-size: 16px;
    border-radius: 9px;
    border-radius: 12px;
    border: 1px solid rgba(172, 172, 172, 0.33);
    background: linear-gradient(0deg, var(--neutral-neutral-420, rgba(18, 18, 18, 0.20)) 0%, var(--neutral-neutral-420, rgba(18, 18, 18, 0.20)) 100%), var(--neutral-neutral-310, rgba(40, 40, 40, 0.10));
    backdrop-filter: blur(23px);
    &.ant-input-outlined {
      background-color: transparent;
      &:focus {
        border-color: #475467;
      }
    }
    &:hover {
      border-color: #475467;
    }
    &::placeholder {
      color: #667085;
      opacity: 1;
    }
    &::-ms-input-placeholder {
      color: #667085;
    }
    .ant-input-suffix {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
`;
