import styled from "styled-components";

export const StarUpContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: 0;
  mask: linear-gradient(180deg, transparent 0, #fff 10%, #fff 90%, transparent);
  overflow: hidden;
  & > div {
    width: 1px;
    height: 1px;
    border-radius: 50%;
    animation: buttonStars 70s linear infinite;
    &:nth-child(1) {
      box-shadow: 1379px 128px #bad6f7, 889px 967px #bad6f7, 329px 968px #bad6f7,
        390px 864px #bad6f7, 372px 1356px #bad6f7, 394px 248px #bad6f7,
        57px 1314px #bad6f7, 174px 772px #bad6f7, 1347px 970px #bad6f7,
        115px 304px #bad6f7, 156px 1024px #bad6f7, 1260px 811px #bad6f7,
        1388px 181px #bad6f7, 726px 1380px #bad6f7, 867px 253px #bad6f7,
        84px 220px #bad6f7, 697px 597px #bad6f7, 983px 1084px #bad6f7,
        172px 653px #bad6f7, 1075px 1274px #bad6f7, 212px 317px #bad6f7,
        341px 409px #bad6f7, 16px 153px #bad6f7, 243px 1124px #bad6f7,
        1155px 879px #bad6f7, 1312px 532px #bad6f7, 967px 740px #bad6f7,
        1307px 694px #bad6f7, 966px 1088px #bad6f7, 445px 729px #bad6f7,
        166px 655px #bad6f7, 387px 859px #bad6f7, 457px 199px #bad6f7,
        1097px 6px #bad6f7, 121px 584px #bad6f7, 910px 156px #bad6f7,
        1056px 968px #bad6f7, 1202px 1348px #bad6f7, 160px 50px #bad6f7,
        971px 89px #bad6f7, 1290px 540px #bad6f7, 763px 1198px #bad6f7,
        367px 883px #bad6f7, 701px 1317px #bad6f7, 12px 812px #bad6f7,
        127px 380px #bad6f7, 703px 198px #bad6f7, 897px 293px #bad6f7,
        698px 57px #bad6f7, 842px 1112px #bad6f7, 991px 9px #bad6f7,
        1295px 1378px #bad6f7, 803px 116px #bad6f7, 1339px 1015px #bad6f7,
        1238px 615px #bad6f7, 220px 233px #bad6f7, 733px 1182px #bad6f7,
        521px 1315px #bad6f7, 809px 712px #bad6f7, 566px 91px #bad6f7,
        1313px 790px #bad6f7, 633px 119px #bad6f7, 1051px 1179px #bad6f7,
        706px 552px #bad6f7, 1149px 416px #bad6f7, 1130px 745px #bad6f7,
        644px 492px #bad6f7, 716px 766px #bad6f7, 123px 286px #bad6f7,
        1014px 1289px #bad6f7, 1358px 453px #bad6f7, 945px 409px #bad6f7,
        479px 1195px #bad6f7, 1071px 340px #bad6f7, 551px 768px #bad6f7,
        570px 844px #bad6f7, 815px 97px #bad6f7, 971px 1182px #bad6f7,
        732px 1389px #bad6f7, 515px 507px #bad6f7, 594px 423px #bad6f7,
        8px 1107px #bad6f7, 660px 817px #bad6f7, 194px 167px #bad6f7,
        1007px 515px #bad6f7, 1181px 239px #bad6f7, 1077px 269px #bad6f7,
        256px 210px #bad6f7, 1129px 539px #bad6f7, 297px 732px #bad6f7,
        1199px 585px #bad6f7, 932px 549px #bad6f7, 440px 1103px #bad6f7,
        720px 1205px #bad6f7, 316px 686px #bad6f7, 94px 1099px #bad6f7,
        110px 443px #bad6f7, 1124px 333px #bad6f7, 841px 771px #bad6f7,
        183px 792px #bad6f7, 97px 7px #bad6f7, 660px 130px #bad6f7,
        1143px 767px #bad6f7, 832px 724px #bad6f7, 1336px 287px #bad6f7,
        581px 84px #bad6f7, 936px 1117px #bad6f7, 1330px 956px #bad6f7,
        790px 1278px #bad6f7, 635px 467px #bad6f7, 1095px 694px #bad6f7,
        965px 435px #bad6f7, 425px 300px #bad6f7, 723px 1233px #bad6f7,
        918px 674px #bad6f7, 976px 1117px #bad6f7, 834px 1301px #bad6f7,
        194px 885px #bad6f7, 196px 1260px #bad6f7, 1127px 638px #bad6f7,
        168px 191px #bad6f7, 138px 290px #bad6f7, 1095px 1130px #bad6f7,
        605px 612px #bad6f7, 269px 1149px #bad6f7, 574px 991px #bad6f7,
        177px 932px #bad6f7, 313px 764px #bad6f7, 959px 791px #bad6f7,
        1000px 1262px #bad6f7, 234px 884px #bad6f7, 301px 213px #bad6f7,
        1236px 339px #bad6f7, 348px 660px #bad6f7, 921px 300px #bad6f7,
        574px 967px #bad6f7, 429px 1321px #bad6f7, 34px 853px #bad6f7,
        166px 348px #bad6f7, 179px 443px #bad6f7, 826px 661px #bad6f7,
        657px 868px #bad6f7, 427px 723px #bad6f7, 1051px 1182px #bad6f7,
        536px 889px #bad6f7, 1396px 962px #bad6f7, 633px 232px #bad6f7,
        711px 268px #bad6f7, 401px 48px #bad6f7, 498px 794px #bad6f7,
        1218px 1358px #bad6f7, 1249px 1004px #bad6f7, 377px 1189px #bad6f7,
        169px 1413px #bad6f7, 407px 842px #bad6f7, 1247px 121px #bad6f7,
        1369px 476px #bad6f7, 76px 1402px #bad6f7, 450px 113px #bad6f7,
        1139px 1270px #bad6f7, 114px 925px #bad6f7, 741px 497px #bad6f7,
        889px 114px #bad6f7, 1043px 738px #bad6f7, 1293px 374px #bad6f7,
        493px 1419px #bad6f7, 282px 875px #bad6f7, 1367px 223px #bad6f7,
        1312px 1058px #bad6f7, 335px 917px #bad6f7, 572px 476px #bad6f7,
        155px 1412px #bad6f7, 173px 1080px #bad6f7, 1043px 919px #bad6f7,
        1057px 1398px #bad6f7, 549px 80px #bad6f7, 205px 1123px #bad6f7,
        604px 100px #bad6f7, 297px 964px #bad6f7, 378px 341px #bad6f7,
        641px 881px #bad6f7, 764px 380px #bad6f7, 1403px 457px #bad6f7,
        847px 553px #bad6f7, 105px 637px #bad6f7, 142px 1089px #bad6f7,
        45px 1180px #bad6f7, 1202px 948px #bad6f7, 1171px 1191px #bad6f7,
        313px 159px #bad6f7, 586px 955px #bad6f7, 697px 446px #bad6f7,
        475px 973px #bad6f7, 252px 705px #bad6f7, 1000px 1392px #bad6f7,
        608px 957px #bad6f7, 314px 849px #bad6f7, 1414px 378px #bad6f7,
        1088px 573px #bad6f7, 1388px 1171px #bad6f7, 723px 1073px #bad6f7,
        124px 584px #bad6f7, 96px 484px #bad6f7, 845px 1109px #bad6f7,
        85px 147px #bad6f7, 515px 587px #bad6f7, 1314px 43px #bad6f7,
        1419px 399px #bad6f7, 418px 391px #bad6f7, 773px 45px #bad6f7,
        430px 658px #bad6f7, 1266px 720px #bad6f7, 566px 1356px #bad6f7,
        804px 241px #bad6f7, 1417px 860px #bad6f7, 1170px 120px #bad6f7,
        282px 777px #bad6f7, 687px 578px #bad6f7, 941px 1226px #bad6f7,
        333px 926px #bad6f7, 1172px 1088px #bad6f7, 1206px 214px #bad6f7,
        459px 927px #bad6f7, 160px 129px #bad6f7, 857px 547px #bad6f7,
        981px 275px #bad6f7, 1142px 524px #bad6f7, 1349px 130px #bad6f7,
        335px 418px #bad6f7, 1219px 81px #bad6f7, 1346px 746px #bad6f7,
        14px 576px #bad6f7, 839px 505px #bad6f7, 129px 1202px #bad6f7,
        323px 43px #bad6f7, 4px 553px #bad6f7, 111px 407px #bad6f7,
        1328px 950px #bad6f7, 1235px 1315px #bad6f7, 171px 543px #bad6f7,
        1336px 818px #bad6f7, 598px 36px #bad6f7, 1029px 1295px #bad6f7,
        562px 90px #bad6f7, 1197px 988px #bad6f7, 470px 293px #bad6f7,
        718px 1412px #bad6f7, 735px 53px #bad6f7, 448px 616px #bad6f7,
        512px 1313px #bad6f7, 1058px 122px #bad6f7, 1385px 293px #bad6f7,
        853px 640px #bad6f7, 906px 1110px #bad6f7, 297px 545px #bad6f7,
        1300px 704px #bad6f7, 1212px 667px #bad6f7, 108px 1151px #bad6f7,
        453px 1098px #bad6f7, 1025px 32px #bad6f7, 93px 59px #bad6f7,
        1209px 217px #bad6f7, 565px 219px #bad6f7, 376px 1387px #bad6f7,
        1291px 384px #bad6f7, 391px 1408px #bad6f7, 1230px 92px #bad6f7,
        348px 1079px #bad6f7, 477px 935px #bad6f7, 640px 773px #bad6f7,
        1419px 563px #bad6f7, 888px 953px #bad6f7, 1389px 62px #bad6f7,
        1090px 576px #bad6f7, 1279px 426px #bad6f7, 974px 576px #bad6f7,
        1131px 1251px #bad6f7, 1079px 515px #bad6f7, 982px 872px #bad6f7,
        1183px 98px #bad6f7, 88px 1386px #bad6f7, 864px 739px #bad6f7,
        1221px 1202px #bad6f7, 1300px 888px #bad6f7, 995px 870px #bad6f7,
        329px 289px #bad6f7, 1209px 1382px #bad6f7, 827px 482px #bad6f7,
        293px 1261px #bad6f7, 696px 367px #bad6f7, 1416px 1123px #bad6f7,
        509px 1239px #bad6f7, 1260px 1169px #bad6f7, 950px 1367px #bad6f7,
        1183px 231px #bad6f7, 394px 362px #bad6f7, 808px 964px #bad6f7,
        1247px 1340px #bad6f7, 44px 684px #bad6f7, 747px 1220px #bad6f7;
    }
    &:nth-child(2) {
      opacity: 0.3;
      box-shadow: 1920px 946px #bad6f7, 485px 262px #bad6f7, 14px 1920px #bad6f7,
        1182px 1127px #bad6f7, 641px 86px #bad6f7, 998px 1920px #bad6f7,
        1242px 1416px #bad6f7, 1077px 832px #bad6f7, 821px 1097px #bad6f7,
        686px 1331px #bad6f7, 549px 600px #bad6f7, 1145px 1108px #bad6f7,
        961px 535px #bad6f7, 300px 805px #bad6f7, 661px 813px #bad6f7,
        841px 419px #bad6f7, 1150px 8px #bad6f7, 885px 1016px #bad6f7,
        985px 754px #bad6f7, 501px 1136px #bad6f7, 868px 1049px #bad6f7,
        518px 680px #bad6f7, 808px 379px #bad6f7, 276px 1415px #bad6f7,
        1034px 156px #bad6f7, 1358px 838px #bad6f7, 1286px 479px #bad6f7,
        421px 1056px #bad6f7, 767px 105px #bad6f7, 549px 1019px #bad6f7,
        887px 1322px #bad6f7, 855px 170px #bad6f7, 292px 283px #bad6f7,
        716px 505px #bad6f7, 447px 216px #bad6f7, 990px 556px #bad6f7,
        577px 1161px #bad6f7, 635px 225px #bad6f7, 577px 1386px #bad6f7,
        998px 17px #bad6f7, 559px 523px #bad6f7, 1127px 514px #bad6f7,
        389px 763px #bad6f7, 1122px 443px #bad6f7, 887px 173px #bad6f7,
        853px 422px #bad6f7, 57px 753px #bad6f7, 355px 1366px #bad6f7,
        689px 1148px #bad6f7, 700px 451px #bad6f7, 451px 1223px #bad6f7,
        1000px 1183px #bad6f7, 903px 1080px #bad6f7, 394px 1142px #bad6f7,
        1108px 692px #bad6f7, 260px 178px #bad6f7, 1196px 1328px #bad6f7,
        591px 452px #bad6f7, 578px 668px #bad6f7, 1127px 1236px #bad6f7,
        573px 239px #bad6f7, 1302px 1298px #bad6f7, 1040px 1209px #bad6f7,
        752px 323px #bad6f7, 856px 1249px #bad6f7, 528px 44px #bad6f7,
        128px 876px #bad6f7, 477px 804px #bad6f7, 91px 678px #bad6f7,
        1373px 1138px #bad6f7, 1363px 620px #bad6f7, 657px 1390px #bad6f7,
        841px 421px #bad6f7, 60px 1156px #bad6f7, 480px 746px #bad6f7,
        1033px 203px #bad6f7, 1038px 744px #bad6f7, 958px 1239px #bad6f7,
        133px 148px #bad6f7, 2px 420px #bad6f7, 211px 212px #bad6f7,
        1032px 112px #bad6f7, 5px 685px #bad6f7, 286px 1267px #bad6f7,
        863px 691px #bad6f7, 1059px 587px #bad6f7, 1403px 642px #bad6f7,
        1106px 1235px #bad6f7, 188px 363px #bad6f7, 947px 929px #bad6f7,
        1106px 216px #bad6f7, 882px 719px #bad6f7, 1044px 813px #bad6f7,
        843px 1310px #bad6f7, 291px 1270px #bad6f7, 529px 1393px #bad6f7,
        693px 543px #bad6f7, 921px 950px #bad6f7, 784px 325px #bad6f7,
        1281px 1419px #bad6f7, 429px 775px #bad6f7, 996px 412px #bad6f7,
        751px 1375px #bad6f7, 1241px 831px #bad6f7, 327px 1182px #bad6f7,
        590px 1026px #bad6f7, 1107px 1268px #bad6f7, 1031px 1112px #bad6f7,
        1123px 997px #bad6f7, 475px 176px #bad6f7, 181px 809px #bad6f7,
        1023px 543px #bad6f7;
    }
    &:nth-child(4) {
      width: 3px;
      height: 3px;
      margin-top: -2px;
      margin-left: -1px;
      filter: blur(2px);
      opacity: 0.4;
    }
    &:nth-child(3),
    &:nth-child(4) {
      box-shadow: 240px 384px #bad6f7, 476px 1028px #bad6f7,
        1396px 725px #bad6f7, 1367px 6px #bad6f7, 1360px 344px #bad6f7,
        65px 418px #bad6f7, 453px 935px #bad6f7, 22px 60px #bad6f7,
        974px 934px #bad6f7, 1023px 1332px #bad6f7, 434px 350px #bad6f7,
        898px 1307px #bad6f7, 1188px 1285px #bad6f7, 842px 872px #bad6f7,
        470px 914px #bad6f7, 518px 803px #bad6f7, 1098px 508px #bad6f7,
        1055px 1344px #bad6f7, 461px 807px #bad6f7, 473px 561px #bad6f7,
        886px 1360px #bad6f7, 1392px 844px #bad6f7, 1186px 1168px #bad6f7,
        563px 1294px #bad6f7, 354px 1206px #bad6f7, 1293px 209px #bad6f7,
        177px 1160px #bad6f7, 772px 1413px #bad6f7, 1348px 805px #bad6f7,
        430px 1420px #bad6f7, 320px 1122px #bad6f7, 45px 592px #bad6f7,
        674px 841px #bad6f7, 352px 1369px #bad6f7, 302px 108px #bad6f7,
        1159px 336px #bad6f7, 433px 1230px #bad6f7, 510px 6px #bad6f7,
        1160px 1150px #bad6f7, 39px 256px #bad6f7, 457px 155px #bad6f7,
        258px 317px #bad6f7, 97px 1166px #bad6f7, 40px 830px #bad6f7,
        1269px 719px #bad6f7, 114px 222px #bad6f7, 1280px 473px #bad6f7,
        426px 713px #bad6f7, 992px 36px #bad6f7, 1246px 1248px #bad6f7,
        1231px 134px #bad6f7, 654px 1004px #bad6f7, 455px 513px #bad6f7,
        561px 221px #bad6f7, 145px 1920px #bad6f7, 192px 109px #bad6f7,
        652px 353px #bad6f7, 809px 325px #bad6f7, 1228px 541px #bad6f7,
        884px 329px #bad6f7, 1158px 184px #bad6f7, 87px 165px #bad6f7,
        673px 414px #bad6f7, 882px 1166px #bad6f7, 592px 1026px #bad6f7,
        873px 1226px #bad6f7, 1145px 860px #bad6f7, 343px 31px #bad6f7,
        351px 1191px #bad6f7, 867px 469px #bad6f7, 1364px 607px #bad6f7,
        622px 256px #bad6f7, 1367px 808px #bad6f7, 1143px 567px #bad6f7,
        1158px 723px #bad6f7, 1156px 76px #bad6f7, 1345px 736px #bad6f7,
        63px 540px #bad6f7, 11px 1289px #bad6f7, 1254px 985px #bad6f7,
        714px 45px #bad6f7, 544px 211px #bad6f7, 790px 1084px #bad6f7,
        218px 54px #bad6f7, 731px 422px #bad6f7, 35px 440px #bad6f7,
        1032px 666px #bad6f7, 841px 806px #bad6f7, 36px 625px #bad6f7,
        470px 1180px #bad6f7, 712px 412px #bad6f7, 940px 972px #bad6f7,
        343px 492px #bad6f7, 1259px 653px #bad6f7, 252px 1196px #bad6f7,
        1160px 89px #bad6f7, 280px 763px #bad6f7, 1034px 655px #bad6f7,
        960px 727px #bad6f7, 1241px 1019px #bad6f7, 486px 642px #bad6f7,
        692px 334px #bad6f7, 760px 1099px #bad6f7, 1046px 1285px #bad6f7,
        152px 1331px #bad6f7, 1156px 461px #bad6f7, 104px 992px #bad6f7,
        279px 377px #bad6f7, 422px 746px #bad6f7, 20px 52px #bad6f7,
        587px 1016px #bad6f7, 408px 819px #bad6f7, 508px 403px #bad6f7,
        685px 793px #bad6f7, 936px 647px #bad6f7, 826px 115px #bad6f7,
        111px 883px #bad6f7, 200px 1348px #bad6f7, 47px 1000px #bad6f7,
        510px 1409px #bad6f7, 318px 14px #bad6f7, 586px 128px #bad6f7,
        16px 892px #bad6f7, 66px 21px #bad6f7, 868px 1124px #bad6f7,
        690px 732px #bad6f7, 94px 391px #bad6f7, 150px 1006px #bad6f7,
        544px 884px #bad6f7, 155px 1274px #bad6f7, 48px 1920px #bad6f7,
        405px 424px #bad6f7, 1333px 745px #bad6f7, 990px 1066px #bad6f7,
        258px 540px #bad6f7, 1243px 1111px #bad6f7, 712px 172px #bad6f7,
        967px 722px #bad6f7, 289px 921px #bad6f7, 1342px 950px #bad6f7,
        674px 205px #bad6f7, 705px 811px #bad6f7, 490px 616px #bad6f7,
        1376px 1367px #bad6f7, 1071px 88px #bad6f7, 1281px 1300px #bad6f7,
        1407px 1055px #bad6f7, 1082px 998px #bad6f7, 1053px 352px #bad6f7,
        8px 52px #bad6f7, 178px 1108px #bad6f7, 143px 1414px #bad6f7,
        39px 1019px #bad6f7, 970px 1060px #bad6f7, 965px 1352px #bad6f7,
        225px 1026px #bad6f7, 1920px 425px #bad6f7, 1396px 880px #bad6f7,
        818px 326px #bad6f7, 1056px 192px #bad6f7, 874px 511px #bad6f7,
        144px 5px #bad6f7, 481px 829px #bad6f7, 1239px 554px #bad6f7,
        16px 278px #bad6f7, 397px 510px #bad6f7, 897px 297px #bad6f7,
        338px 4px #bad6f7, 138px 990px #bad6f7, 604px 1064px #bad6f7,
        742px 843px #bad6f7, 1043px 1412px #bad6f7, 492px 501px #bad6f7,
        164px 1306px #bad6f7, 951px 1049px #bad6f7, 1085px 386px #bad6f7,
        385px 1161px #bad6f7, 1089px 895px #bad6f7, 1100px 638px #bad6f7,
        969px 775px #bad6f7, 886px 17px #bad6f7, 1030px 857px #bad6f7,
        396px 89px #bad6f7, 583px 740px #bad6f7, 565px 1262px #bad6f7,
        1364px 452px #bad6f7, 538px 1202px #bad6f7, 470px 1180px #bad6f7,
        302px 1137px #bad6f7, 1181px 1316px #bad6f7, 981px 979px #bad6f7,
        1288px 684px #bad6f7, 374px 407px #bad6f7, 679px 706px #bad6f7,
        1233px 434px #bad6f7, 521px 548px #bad6f7, 622px 325px #bad6f7,
        765px 1322px #bad6f7, 1408px 821px #bad6f7, 581px 129px #bad6f7,
        102px 1312px #bad6f7, 1384px 1129px #bad6f7, 432px 979px #bad6f7,
        1011px 1339px #bad6f7, 923px 1048px #bad6f7, 1400px 827px #bad6f7,
        281px 429px #bad6f7, 363px 182px #bad6f7, 1045px 665px #bad6f7,
        1245px 1032px #bad6f7, 41px 1002px #bad6f7, 1339px 314px #bad6f7,
        717px 679px #bad6f7, 1381px 213px #bad6f7, 1091px 488px #bad6f7,
        971px 898px #bad6f7, 1043px 864px #bad6f7, 42px 1411px #bad6f7,
        879px 1105px #bad6f7, 687px 196px #bad6f7, 25px 441px #bad6f7,
        216px 765px #bad6f7, 921px 750px #bad6f7, 555px 1161px #bad6f7,
        863px 327px #bad6f7, 708px 28px #bad6f7, 13px 260px #bad6f7,
        118px 1004px #bad6f7, 1217px 811px #bad6f7, 892px 526px #bad6f7,
        55px 537px #bad6f7, 992px 1296px #bad6f7, 509px 632px #bad6f7,
        639px 390px #bad6f7, 1412px 694px #bad6f7, 737px 676px #bad6f7,
        412px 511px #bad6f7, 1280px 324px #bad6f7, 1200px 1236px #bad6f7,
        1141px 1016px #bad6f7, 887px 924px #bad6f7, 1042px 323px #bad6f7,
        204px 1260px #bad6f7, 829px 174px #bad6f7, 916px 634px #bad6f7,
        619px 766px #bad6f7, 1331px 207px #bad6f7, 732px 321px #bad6f7,
        767px 916px #bad6f7, 798px 946px #bad6f7, 739px 175px #bad6f7,
        553px 405px #bad6f7, 952px 1215px #bad6f7, 418px 1392px #bad6f7,
        780px 1017px #bad6f7, 1157px 900px #bad6f7, 771px 638px #bad6f7,
        740px 969px #bad6f7, 675px 768px #bad6f7, 1010px 673px #bad6f7,
        1189px 1135px #bad6f7, 18px 1045px #bad6f7, 20px 611px #bad6f7,
        1154px 719px #bad6f7, 1407px 1324px #bad6f7, 397px 1053px #bad6f7,
        1149px 474px #bad6f7, 669px 1212px #bad6f7, 1126px 171px #bad6f7,
        955px 1412px #bad6f7, 576px 24px #bad6f7, 1386px 1349px #bad6f7,
        29px 673px #bad6f7, 1076px 800px #bad6f7, 617px 1407px #bad6f7,
        922px 396px #bad6f7, 911px 819px #bad6f7, 1203px 523px #bad6f7,
        637px 953px #bad6f7, 405px 88px #bad6f7, 466px 1308px #bad6f7,
        66px 1155px #bad6f7, 541px 484px #bad6f7, 140px 282px #bad6f7,
        374px 1376px #bad6f7, 1061px 569px #bad6f7, 85px 638px #bad6f7,
        357px 910px #bad6f7, 1043px 320px #bad6f7, 217px 262px #bad6f7,
        539px 561px #bad6f7, 1297px 515px #bad6f7, 647px 404px #bad6f7,
        285px 1304px #bad6f7, 736px 1283px #bad6f7, 1332px 1140px #bad6f7,
        984px 303px #bad6f7, 535px 725px #bad6f7, 724px 701px #bad6f7,
        483px 960px #bad6f7;
    }
    &::before {
      content: "";
      position: absolute;
      top: 100px;
      width: inherit;
      height: inherit;
      border-radius: inherit;
      background: transparent;
      box-shadow: inherit;
    }
  }
  @keyframes buttonStars {
    0% {
      transform: translateZ(0) translateY(0);
    }
    100% {
      transform: translateZ(0) translateY(-900px);
    }
  }
`;
