import { Link, useNavigate } from "react-router-dom";
import { AuthBlock, AuthForm, AuthLogo } from "../../../Layout/Auth/styled";
import { BorderAnimation } from "../../../Layout/styled";
import { AuthTitle, TextForgot } from "../Login/styled";
import { VerifyContainer } from "./styled";
import auth_logo from "../../../assets/Auth/form_icon.png";
import { getAuth, sendEmailVerification } from "firebase/auth";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import toast from "react-hot-toast";
import { ButtonStyle, ButtonStyle2 } from "../../Homev2/styled";
import StarUp from "../../../components/StarUp";

const Verify = () => {
  const auth = getAuth();
  const [token, setToken] = useState<any>(null);
  const navigate = useNavigate();
  const onRecaptchaChange = (token: any) => {
    setToken(token);
  };

  // Handle verify
  const verifyEmail = async () => {
    if (token && token.length > 0) {
      try {
        await sendEmailVerification(auth.currentUser!);
        toast("Email Verification Sent !");
        navigate("/auth/login");
      } catch (err) {
        console.log("[err]", err);
      }
    } else {
      toast.error("Please check captcha !");
    }
  };

  return (
    <VerifyContainer>
      <AuthForm>
        <AuthLogo>
          <img src={auth_logo} alt="logo" />
        </AuthLogo>
        <AuthTitle>
          <h2>Please confirm email !</h2>
          <p>Please check your email:<br />
            {auth && auth?.currentUser?.email}
          </p>
        </AuthTitle>
        {/* <AuthBlock style={{ textAlign: "center", marginBottom: "25px" }}>
          <label>Please confirm email !</label>
          <label>
            Please check your email: {auth && auth?.currentUser?.email}
          </label>
        </AuthBlock> */}
        {/* <BorderAnimation /> */}
        <ReCAPTCHA
          theme="dark"
          size="normal"
          sitekey="6LfXAf4pAAAAABYEoKRMEt4heLQF3SonFwvX7KwT"
          onChange={onRecaptchaChange}
        />
        <ButtonStyle2 style={{ marginTop: "20px" }}>
          <BorderAnimation />
          <button onClick={verifyEmail}>
            {/* <StarUp /> */}
            <span>Resend Email</span>
          </button>
        </ButtonStyle2>
        <TextForgot className="single">
          <Link to="/auth/login">Back To Login</Link>
        </TextForgot>
      </AuthForm>
    </VerifyContainer>
  );
};

export default Verify;
