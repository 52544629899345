import styled from "styled-components";
import decor_02 from "../../assets/BuyAMFI/decor_02.png";
import { ButtonCommonSubmit, LinearGardientLayer } from "../../Layout/styled";

export const BuyContainer = styled.div`
  overflow: hidden;
  position: relative;
  padding-top: 80px;
  @media screen and (max-width: 767px) {
    ${LinearGardientLayer} {
      display: none;
    }
  }
`;
export const BuyWrapper = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding: 30px 20px 60px;
`;
export const BuyTitle = styled.h2`
  color: #fff;
  text-align: center;
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 30px;
  span {
    margin-left: 10px;
    background: linear-gradient(90deg, #c44cff 0%, #5c00fc 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 767px) {
    font-size: 30px;
  }
`;
export const BuyContent = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 25px;
  ${ButtonCommonSubmit} {
    width: 100%;
    height: 50px;
    span {
      font-size: 16px;
      font-weight: bold;
      background: linear-gradient(90deg, #c44cff 0%, #5c00fc 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;
export const BuyInfo = styled.div`
  width: 50%;
  & > p {
    position: relative;
    display: flex;
    align-items: center;
    color: #d0d5dd;
    font-size: 20px;
    font-style: italic;
    letter-spacing: -0.6px;
    margin-bottom: 10px;
    &::before {
      content: "";
      position: relative;
      display: block;
      width: 10px;
      height: 10px;
      margin: 0 8px 5px;
      border-radius: 50%;
      background-color: #d0d5dd;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    & > p {
      font-size: 17px;
      &::before {
        margin-bottom: 2px;
      }
    }
  }
`;
export const BuyPresale = styled.div`
  position: relative;
  width: 50%;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #fcfcfd;
  background: #020202;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: url(${decor_02}) no-repeat center / 100% auto;
    width: 95px;
    height: 105px;
  }
  @media screen and (max-width: 767px) {
    width: calc(100% - 20px);
    padding: 20px 10px;
  }
`;
export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  min-height: 250px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  @media screen and (max-width: 767px) {
    min-height: 220px;
  }
`;
export const BlockBig = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: calc(100% - 62px);
  padding: 20px 16px;
  min-height: 226px;
  border-radius: 12px;
  background: #27282c;
  @media screen and (max-width: 767px) {
    min-height: 180px;
  }
`;
export const BlockSub = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 285px;
  width: 100%;
  gap: 12px;
`;
export const SubInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100px;
  padding: 12px 16px;
  border-radius: 12px;
  border: 1px solid #1d2939;
  background: #000;
  @media screen and (max-width: 767px) {
    min-height: 80px;
  }
`;
export const PresaleTitle = styled.div`
  color: #fcfcfd;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 10px;
`;
export const BuyText = styled.p`
  color: #fcfcfd;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  @media screen and (max-width: 767px) {
    font-size: 13px;
  }
`;
export const BuyValue = styled.p`
  color: #b692f6;
  font-size: 20px;
  font-weight: 600;
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`;
export const BuyDecor = styled.div`
  max-width: 32px;
  width: 100%;
  margin-bottom: auto;
  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    max-width: 22px;
  }
`;
export const BlockCopy = styled.div`
  position: absolute;
  right: 20px;
  top: 25px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  img {
    width: 100%;
  }
`;
export const PresaleLogo = styled.div`
  max-width: 50px;
  width: 100%;
  margin-bottom: 20px;
  img {
    width: 100%;
  }
`;
export const PresalePrice = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  line-height: 22px;
  margin-bottom: 8px;
  span {
    padding-right: 5px;
    color: #9e77ed;
    font-weight: 500;
  }
`;
export const PresaleHint = styled.p`
  color: #d0d5dd;
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 22px;
`;
export const PresaleCW = styled.div`
  width: 150px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
  color: #fcfaff;
  border-radius: 8px;
  background: #7f56d9;
  cursor: pointer;
`;
export const PresaleInputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 9px;
  border: 1px solid #9e77ed;
  background: #010102;
  gap: 20px;
  min-height: 48px;
  margin-bottom: 10px;
`;
export const PresaleInput = styled.div`
  width: 100%;
  min-height: 48px;
  padding-left: 10px;
  input {
    width: 100%;
    height: 48px;
    background-color: transparent;
    outline: none;
    border: none;
    color: #fff;
    font-size: 16px;
  }
`;
export const PresaleCurrency = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  min-height: 48px;
  border-radius: 8px;
  background: #fcfcfd;
  p {
    background: linear-gradient(90deg, #c44cff 0%, #5c00fc 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
    font-weight: 700;
  }
`;
export const PresaleValuePick = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 66px;
    height: 30px;
    border-radius: 6px;
    background: transparent;
    border: 1px solid #7f56d9;
    font-size: 12px;
    font-weight: 700;
    color: #fcfcfd;
    transition: all 0.4s ease;
    cursor: pointer;
    &:hover {
      background-color: #7f56d9;
      border-color: transparent;
    }
  }
`;
export const PresaleConvert = styled.div`
  border-radius: 9px;
  background: #1f1f1f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 20px;
  margin-bottom: 20px;
`;
export const ConvertValue = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    font-size: 16px;
    word-break: break-word;
    color: #fcfcfd;
  }
  img {
    width: 24px;
    height: 24px;
  }
`;
export const ConvertCurrency = styled.p`
  font-size: 14px;
  font-weight: bold;
  background: linear-gradient(90deg, #c44cff 0%, #5c00fc 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
