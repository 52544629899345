import { styled } from "styled-components";

export const AMFITokenStyle = styled.div`
  position: relative;
  min-height: 914px;
  z-index: 1;
  padding-top: 150px;
  &:before {
    position: absolute;
    content: "";
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    background: url("/assets/images/TokenNomic/bg.png");
    width: 1214px;
    height: 1214px;
    z-index: 0;
    pointer-events: none;
  }
  /* &:after {
    position: absolute;
    content: "";
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    background: url('/assets/images/TokenNomic/bg_02.png') top center / cover no-repeat;
    width: 1440px;
    height: 660px;
    z-index: -1;
    pointer-events: none;
  } */
  .gif-for-ai-metafi {
    margin: 0 auto;
    max-width: 1075px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 5;
    .gif {
      position: relative;
      width: 320px;
      height: 320px;
      margin-bottom: 240px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -100px;
      &::before {
        content: "";
        position: absolute;
        left: -60px;
        top: 150px;
        z-index: -1;
        background-image: url("/assets/background/bg-of-gif.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 459px;
        height: 339px;
      }
      img {
        width: 100%;
        object-fit: cover;
        margin-bottom: -60px;
      }
    }
    @media screen and (max-width: 767px) {
      margin-right: 0px;
      .gif {
        width: 300px;
        height: 300px;
        margin-left: 0px;
        &::before {
          left: -75px;
          top: 130px;
        }
      }
    }
    @media screen and (max-width: 481px) {
      margin-right: 0px;
      background-position: 54%;
      background-size: 200%;
      .gif {
        width: 280px;
        height: 280px;
        margin-left: 0px;
      }
    }
  }
  .title-amfi-token {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
    padding: 0 20px;
    h1 {
      margin: 20px 0;
      background: linear-gradient(0deg, #fefefe 0%, #ffffff 100%);
      background-clip: text;
    }
    p {
      color: #b4b4b4;
      text-align: center;
      font-family: Inter;
      font-size: 12.375px;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: unset;
  }
`;
export const AMFIImgToken = styled.div`
  text-align: center;
  width: 110px;
  margin: 0 auto -20px;
`;
export const AMFITokenModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(13px);
  }
`;
export const AMFIModalClose = styled.div`
  width: 32px;
  height: 32px;
  margin-left: auto;
  margin-bottom: 20px;
  cursor: pointer;
  img {
    width: 100%;
  }
`;

export const ContentChart = styled.div`
  position: relative;
  text-align: center;
  .chart {
    max-width: 1160px;
    width: 100%;
    margin: 0 auto;
  }
  .apexcharts-legend.apx-legend-position-right {
    flex-direction: unset;
    max-width: 404px;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: unset;
    bottom: unset;
    gap: 5px 0;
    top: 50% !important;
    transform: translateY(-50%);
  }
  .apexcharts-legend-series {
    border-radius: 100px;
    border: 1px solid var(--neutral-neutral-110, rgba(255, 255, 255, 0.1));
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(2px);
    padding: 6px 17px;
    .apexcharts-legend-text {
      color: var(--Colors-Primary-Colors-Gray-300, #d0d5dd) !important;
      font-family: Inter !important;
      font-size: 16.968px !important;
      font-style: normal !important;
    }
  }
  @media screen and (max-width: 1023px) {
    .apexcharts-legend.apx-legend-position-right {
      position: static;
    }
  }
`;
export const AMFIMListToken = styled.ul`
  max-width: 1040px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 50px;
  li {
    width: calc(100% / 6 - 10px);
    border-radius: var(--Parameters-Radius-xl, 14px);
    border: 1px solid #212128;
    background: radial-gradient(
      79.19% 79.19% at 49.22% 92.12%,
      #37373c 0%,
      #000 100%
    );
    padding: 14px;
    &.wallet {
      padding: 0;
      position: relative;
      a {
        padding: 14px;
        display: block;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      cursor: pointer;
      h3 {
        display: flex;
      }
      img {
        position: relative;
        right: -3px;
        cursor: pointer;
      }
    }
    span {
      color: var(--Text-Secondary, rgba(248, 248, 248, 0.7));
      font-family: Inter;
      font-size: 14px;
      display: block;
      text-align: left;
      margin-bottom: 7px;
    }
    h3 {
      color: var(--Text-Text-Primary, rgba(248, 248, 248, 0.95));
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
    }
  }
  .copy {
    position: absolute;
    right: 12px;
    bottom: 12px;
  }
  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 15px;
    li {
      width: calc(50% - 7px);
      padding: 14px 20px;
      &.wallet {
        a {
          padding: 14px 20px;
        }
      }
      h3 {
        font-size: 15px;
      }
    }
    .copy {
      right: 15px;
    }
  }
`;
export const ContentCenterChart = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 5;
  margin-left: -200px;
  z-index: 5;
  transform: translate(-50%, -50%);
  & > p {
    font-size: 30px;
    color: #fff;
    margin-bottom: 10px;
  }
  figure {
    width: 78px;
    margin: 0 auto;
    img {
      width: 100%;
      height: auto;
    }
  }
  @media screen and (max-width: 767px) {
    margin-left: 0px;
    & > p {
      font-size: 25px;
    }
  }
`;
