import styled from "styled-components";

export const PartnerContainer = styled.div`
  margin-bottom: 100px;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    overflow: hidden;
    margin-bottom: 50px;
  }
`;
export const PartnerWrapper = styled.div`
  max-width: 1160px;
  margin: 48px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  @media screen and (max-width: 1023px) {
    max-width: 800px;
    flex-direction: column;
  }
  @media screen and (max-width: 830px) {
    max-width: 600px;
  }
  @media screen and (max-width: 767px) {
    max-width: 320px;
    margin-top: 0;
    padding: 0;
  }
`;
export const PartnerTitle = styled.div`
  width: 38%;
  font-size: 35px;
  line-height: 44.747px;
  background: linear-gradient(0deg, #B6C2F1 0%, #EBEFFF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 1023px) {
    text-align: center;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 767px) {
    display: inline;
    font-size: 24px;
    width: 100%;
    margin-bottom: 50px;
  }
`
export const PartnerMarquee = styled.div`
  width: 100%
`
export const PartnerItem = styled.div`
  max-width: 235px;
  width: 100%;
  margin: 0 20px;
  img {
    width: 100%;
  }
`