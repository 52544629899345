import { styled } from "styled-components";
import { PresaleCW } from "../../page/BuyAMFI/styled";

export const BoxChatStyle = styled.div`
  background-image: url("/assets/background/bg-ai-chat.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  .Typewriter {
    text-align: center;
    margin-top: 20px;
  }
  .Typewriter__wrapper {
    color: #fff;
  }
  .box-container {
    padding: 120px 20px 0;
    margin: 0 auto;
    position: relative;
    z-index: 99;
    padding-bottom: 60px;
    .new-chat-mobile {
      display: none;
    }
    .box {
      max-width: 1160px;
      margin: auto;
      border-radius: 16px;
      border: 1px solid #72427d;
      justify-content: center;
      .box-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.1);
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        padding: 10px 20px;
        .logo {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 16px;
          align-items: center;
        }
      }
      .box-content {
        display: flex;
        justify-content: center;
        width: 100%;
        .form-chat {
          width: calc(100% - 320px);
          padding: 16px;
          border-right: 1px solid #a1aee538;
          background: rgba(0, 0, 0, 0.5);
          backdrop-filter: blur(30px);
          border-bottom-left-radius: 16px;
          .content-chat {
            width: 100%;
            overflow-y: auto;
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            height: calc(100vh - 290px);
            overflow-y: auto;
            @media screen and (max-width: 767px) {
              min-height: calc(100vh - 350px);
              height: unset;
            }
            .list-content {
              height: 100%;
            }

            .last-time-send-mess {
              color: #fcfdfe;
              text-align: center;
              font-family: Inter;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 15.6px */
            }
            .avt-gpt,
            .avt-user {
              display: flex;
              width: 34px;
              height: 34px;
              justify-content: center;
              align-items: center;
              border-radius: 9px;
              img {
                width: 100%;
              }
            }
            .avt-gpt {
              width: 20px;
              height: 27px;
            }
            .content {
              position: relative;
              display: flex;
              max-width: calc(100% - 54px);
              padding: 10px;
              align-items: flex-start;
              gap: 10px;
              border-radius: 9px;
              position: relative;
              p {
                margin: 0;
              }
            }
            .mess-content {
              display: flex;
              align-items: flex-end;
              margin-top: 10px;
              p {
                color: #fafbffe3;
                font-size: 13px;
              }
              .avt-gpt {
                margin-right: 10px;
              }
              .avt-user {
                margin-left: 10px;
              }
            }
            .mess-of-gpt {
              .content {
                background-color: #383838;
                .img-corner {
                  position: absolute;
                  bottom: -6px;
                  left: -12px;
                }
              }
            }
            .mess-of-sender {
              flex-direction: row-reverse;
              .content {
                background-color: #3b3949;
              }
            }
          }
          .input-chat {
            gap: 8px;
            padding: 5px 10px;
            align-items: center;
            border-radius: 10px;
            background: rgb(56, 56, 56, 0.5);
            position: relative;
            display: flex;
            border: 1px solid #6d547666;
            .btn-chat-ai {
              background-color: transparent;
              border: none;
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              cursor: pointer;
              img {
                width: 100%;
              }
            }
            input {
              width: 100%;
              border: none;
              padding: 5px 10px;
              background-color: transparent;
              font-size: 13px;
              color: #fcfdfe;
              font-family: Inter;
              font-style: normal;
              font-weight: 400;
              line-height: 20.405px; /* 169.183% */
              letter-spacing: 0.268px;
              position: relative;
              z-index: 2;
              &:focus {
                outline: none;
              }
            }
          }
        }
        .histoty {
          width: 320px;
          height: calc(100vh - 205px);
          background-color: rgb(0, 0, 0, 0.3);
          border-bottom-right-radius: 17px;
          h3 {
            padding-left: 16px;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 44.747px; /* 124.372% */
            letter-spacing: -0.373px;
            background: linear-gradient(0deg, #b6c2f1 0%, #ebefff 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            .total {
              margin-left: 2px;
              display: inline;
              padding: 2px 5px;
              border-radius: 4px;
              background: rgba(188, 200, 255, 0.01);
              box-shadow: 0px 0px 29.831px 0px rgba(153, 146, 217, 0.23) inset;
              border: none;
            }
          }
          .block-history {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: calc(100% - 60px);
            position: relative;
            .list {
              height: calc(100% - 60px);
              overflow-y: auto;
              .item-history {
                cursor: pointer;
                gap: 16px;
                border-bottom: 1px solid #a1aee538;
                padding: 13px 16px;
                position: relative;
                background: rgba(0, 0, 0, 0.4);
                p {
                  color: #9299b4b2;
                  font-family: Inter;
                  font-size: 13.061px;
                  font-style: normal;
                  line-height: 20.405px; /* 169.183% */
                  letter-spacing: 0.268px;
                }
                button {
                  position: absolute;
                  right: 10px;
                  top: 25%;
                  padding: 5px 10px;
                  border-radius: 6px;
                  border: none;
                  cursor: pointer;
                }
              }
            }
            .new-chat {
              padding: 0 16px;
              button {
                width: 100%;
                margin: 0 auto;
                padding: 13px 20px;
                background: rgba(188, 200, 255, 0.07);
                border: 1px solid rgba(188, 200, 255, 0.5);
              }
              .image-slide {
                width: 22px;
                height: 22px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .image {
              position: absolute;
              z-index: -1;
              bottom: -20px;
              right: 0px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 769px) {
      padding-top: 85px;
      .new-chat-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 16px;
        button {
          width: 100%;
          margin: 0 auto;
          padding: 8px 15px;
          background: rgba(188, 200, 255, 0.07);
          border: 1px solid rgba(188, 200, 255, 0.5);
        }
        .image-slide {
          width: 33px;
          height: 33px;
          img {
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
      }
      .box {
        margin: 0 auto;
        .box-header {
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.2);
          .logo {
            .image {
              display: none;
            }
          }
        }
        .box-content {
          .form-chat {
            width: 100%;
            border-right: 0;
            border-radius: 0;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
          }
        }
        .histoty {
          display: none;
        }
      }
    }
  }
  ${PresaleCW} {
    margin-bottom: 0;
  }
`;
export const LoadingRobot = styled.img`
  width: 25%;
  display: block;
  margin: 10px auto 0;
`;
