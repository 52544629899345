import styled from "styled-components";
import bg from "../../assets/Dashboard/dashboard_bg.jpg";

export const DashboardContainer = styled.div`
  position: relative;
  background: url(${bg}) no-repeat center / cover;
`;
export const DashboardContent = styled.div`
  min-height: 100vh;
`;
export const DashboardBody = styled.div`
  width: calc(100% - 292px);
  transition: all 0.25s ease-in;
  margin-left: auto;
  padding: 30px;
  padding-top: 110px;
  overflow: hidden;
  &.collapse {
    width: calc(100% - 100px);
  }
  @media screen and (max-width: 1375px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media screen and (min-width: 1222px) and (max-width: 1375px) {
    width: calc(100% - 292px);
  }
  @media screen and (min-width: 1024px) and (max-width: 1221px) {
    width: calc(100% - 220px);
  }
  @media screen and (max-width: 1023px) {
    width: 100%;
    margin-top: 0px;
    padding-top: 100px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    overflow: auto;
    height: fit-content;
  }
`;
