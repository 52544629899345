import { IconUpload, ImageUploaded, UploadBlock, UploadBlockWrapper, UploadTitle } from "../KYC/styled";
import upload from "../../assets/Kyc/upload.svg";
import { getAuth } from "firebase/auth";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

const Upload = ({ title, isFront, onUrl }: any) => {
  const {t} = useTranslation()
  const [downloadURL, setDownloadURL] = useState(null);
  const auth = getAuth();

  const handleChange = (event: any) => {
    const file = event.target.files[0];
    // Kiểm tra nếu tệp là một hình ảnh trước khi đặt vào trạng thái
    if (file && file.size > MAX_FILE_SIZE) {
      return toast.error("Max file size: 5MB !");
    }
    if (file && file.type.startsWith("image/")) {
      handleUpload(file);
    } else {
      // Nếu không phải hình ảnh, có thể thông báo cho người dùng hoặc xử lý theo cách khác
      return toast.error("Require Image only !");
    }
  };

  const handleUpload = async (file: any) => {
    if (file) {
      const storage = getStorage();
      const storageRef = ref(
        storage,
        `/imagesKyc/${auth?.currentUser?.uid}/${auth?.currentUser?.uid}_${
          isFront ? "1" : "2"
        }.png`
      );
      try {
        await uploadBytes(storageRef, file);
        const url: any = await getDownloadURL(storageRef);
        setDownloadURL(url);
        onUrl(url);
      } catch (error: any) {
        console.error("Error uploading file:", error.message);
      }
    }
  };

  return (
    <UploadBlock>
      <UploadTitle>{title}</UploadTitle>
      <UploadBlockWrapper
        style={{
          padding: downloadURL ? "0" : ""
        }}
      >
        <input type="file" accept="image/*" onChange={handleChange} />
        {downloadURL ? (
          <ImageUploaded
            src={downloadURL}
            alt="Uploaded"
          />
        ) : (
          <>
            <IconUpload src={upload} alt="upload" />
            <div>
              <p>
                <span className="underline">{t("Click to upload")}</span> {t("or Drag and drop.")}
              </p>
              <p>
                {t("Supported Format:")} <span>JPG, PNG</span>
              </p>
            </div>
          </>
        )}
      </UploadBlockWrapper>
    </UploadBlock>
  );
};

export default Upload;
