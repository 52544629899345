import {
  AddressText,
  AddressTitle,
  BlockLogo,
  FooterAIMETAFI,
  FooterAddress,
  FooterBlock,
  FooterWrapper,
  FooterWrapperTop,
  LogoFooter,
} from "./styled";
import logo from "../../assets/Header/logo.png";
import social_1 from "../../assets/Footer/social_1.svg";
import social_2 from "../../assets/Footer/social_2.svg";
import social_3 from "../../assets/Footer/social_3.svg";
import social_4 from "../../assets/Footer/social_4.svg";
import social_5 from "../../assets/Footer/social_5.svg";
import social_6 from "../../assets/Footer/social_6.svg";
import { useTranslation } from "react-i18next";

const Footer = ({ footerLayout, footerWrapper }: any) => {
  const { t } = useTranslation();
  const LIST_LINK = [
    {
      title: `${t("Get started")}`,
      links: [
        {
          label: `${t("Create Account")}`,
          link: "/",
        },
        {
          label: `${t("Sign In")}`,
          link: "/",
        },
        {
          label: `${t("Forgot Password")}`,
          link: "/",
        },
      ],
    },
    {
      title: `${t("Navigation")}`,
      links: [
        {
          label: `${t("Home")}`,
          link: "/",
        },
        {
          label: `${t("About")}`,
          link: "/",
        },
        {
          label: `${t("Features")}`,
          link: "/",
        },
        {
          label: `${t("Integration")}`,
          link: "/",
        },
        {
          label: `${t("Pricing")}`,
          link: "/",
        },
      ],
    },
    {
      title: `${t("Navigation")}`,
      links: [
        {
          label: `${t("Latest Insights")}`,
          link: "/",
        },
        {
          label: `${t("Contact Us")}`,
          link: "/",
        },
      ],
    },
  ];

  return (
    <FooterAIMETAFI style={footerLayout}>
      <FooterWrapper style={footerWrapper}>
      <FooterWrapperTop>
          <BlockLogo>
            <LogoFooter>
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </LogoFooter>
            <p>AISOLARS © 2024</p>
            <p>
              {t(
                "Envision a future where AI propels your financial achievements and sparks your creativity in unprecedented ways."
              )}
            </p>
          </BlockLogo>
          <div className="block-list-link">
            {LIST_LINK.map((item: any, index: number) => {
              return (
                <div key={index} className="list-link">
                  <h3>{item.title}</h3>
                  <ul>
                    {item.links.map((link: any, kdex: number) => {
                      return (
                        <li key={kdex}>
                          <a href={link.link}>{link.label}</a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
      </FooterWrapperTop>
        <div className="list-social">
          <div className="icon-social">
            {footerSocial.map((item: any, index: number) => {
              return (
                <a key={index} href={item.link}>
                  <img src={item.img} alt="img" />
                </a>
              );
            })}
          </div>
          <div className="description">
            <p>{t("AISOLARS All right Reserved")}</p>
          </div>
        </div>
      </FooterWrapper>
    </FooterAIMETAFI>
  );
};

const footerSocial = [
  {
    id: 1,
    img: social_1,
    link: "",
  },
  {
    id: 2,
    img: social_1,
    link: "",
  },
  {
    id: 3,
    img: social_2,
    link: "",
  },
  {
    id: 4,
    img: social_3,
    link: "",
  },
];

export default Footer;
