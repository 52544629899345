import { ButtonStyle } from "../../page/Homev2/styled";
import {
  BannerContainer,
  BannerContainerStyle,
  BannerImgCenterStyle,
  BannerImgLeftStyle,
  BannerImgRightStyle,
  BannerLeftStyle,
  BannerRightStyle,
  BannerStyle,
  ButtonScrollDownStyle,
  ButtonScrollDownWraperStyle,
  TextSolution,
} from "./styled";
import StarUp from "../StarUp";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Banner() {
  const { t } = useTranslation()
  return (
    <BannerContainer>
      <BannerStyle id="home">
        <BannerContainerStyle>
          <TextSolution>
            <span>AI Solutions</span>
          </TextSolution>
          <BannerLeftStyle>
            <h1>
            Your AI-Enhanced <br />Pathway to DeFi
            </h1>
            <p>
              {t("Envision a future where AI propels your financial achievements and sparks your creativity in unprecedented ways.")}
            </p>
            <div>
              <ButtonStyle>
                <span>Go to Staking</span>
              </ButtonStyle>
            </div>
          </BannerLeftStyle>
          <BannerRightStyle>
            {/* <BannerImgLeftStyle>
              <img src="/assets/images/img-banner-l.png" alt="banner" />
            </BannerImgLeftStyle> */}
            <BannerImgCenterStyle>
              <img src="/assets/images/img-banner-main.png" alt="banner" />
            </BannerImgCenterStyle>
            {/* <BannerImgRightStyle>
              <img src="/assets/images/img-banner-r.png" alt="banner" />
            </BannerImgRightStyle> */}
          </BannerRightStyle>
        </BannerContainerStyle>
        {/* <ButtonScrollDownWraperStyle>
          <ButtonScrollDownStyle href="#versionAndMission">
            <button>
              <img src="/assets/icons/icon-down.png" alt="icon-down" />
            </button>
            <h1>{t("SCROLL DOWN")}</h1>
          </ButtonScrollDownStyle>
        </ButtonScrollDownWraperStyle> */}
      </BannerStyle>
    </BannerContainer>
  );
}
