import styled from "styled-components";

export const LoginContainer = styled.div`
  width: 100%;
  color: #fff;
  input {
    color: #fff;
  }
`;
export const TextForgot = styled.p`
  color: #D3D3D3;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.3px;
  margin-top: 100px;
  &.single {
    a {
      border: none;
      background: transparent;
      background-blend-mode: unset;
      backdrop-filter: unset;
      box-shadow: unset;
      color: #D3D3D3;
      font-weight: 400;
    }
  }
  a {
    padding: 10px 20px 13px;
    font-weight: 700;
    margin-left: 15px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(40, 40, 40, 0.70);
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    font-size: 14px;
    color: #fff;
  }
  @media screen and (max-width: 767px) {
    margin-top: 50px;
    a {
      display: block;
      width: max-content;
      margin: 20px auto 0;
    }
  }
`;
export const AuthTitle = styled.div`
  font-family: "DM Sans", sans-serif;
  text-align: center;
  margin-bottom: 40px;
  h2 {
    position: relative;
    font-size: 24px;
    font-weight: bold;
    width: max-content;
    margin: 0 auto 40px;
    color: #fff;
    &::before,
    &::after {
      content: "";
      position: absolute;
      border-radius: 120px;
      width: 105px;
      height: 1px;
      top: 50%;
    }
    &::before {
      left: -120px;
      background: linear-gradient(-270deg, rgba(0, 0, 0, 0.00) 0%, #E3E3E3 100%);
    }
    &::after {
      right: -120px;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, #E3E3E3 100%);
    }
  }
  p {
    font-size: 20px;
    letter-spacing: -0.431px;
    line-height: 27.6px;
    color: #D3D3D3;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
    h2 {
      font-size: 20px;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
    }
  }
`
