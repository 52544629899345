import { styled } from "styled-components";
import { BorderAnimation } from "../../Layout/styled";
import bg from "../../assets/Faq/Background.png";

export const HomeWrapper = styled.div`
  position: relative;
  @media screen and (max-width: 1023px) {
    overflow: hidden;
  }
`;

export const TokenNoMicWrapper = styled.div`
  position: relative;
  z-index: 3;
  background-color: #0D0D0D;
  border-top-left-radius: 250px;
  border-top-right-radius: 250px;
  @media screen and (min-width: 1024px) {
    border-top-left-radius: 12vw;
    border-top-right-radius: 12vw;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    border-top-left-radius: 19vw;
    border-top-right-radius: 19vw;
  }
  @media screen and (max-width: 767px) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const HomeStyle = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  @media screen and (max-width: 1023px) {
    max-width: 800px;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    p {
      font-size: 15px !important;
    }
  }
`;
export const ButtonStyle = styled.div`
  position: relative;
  border-radius: var(--Parameters-Radius-xl, 14px);
  border: 1px solid #a2eaa1;
  box-shadow: 0px 0px 18.108px 0px rgba(255, 255, 255, 0.06) inset;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  button {
    background-color: unset;
    border: unset;
    cursor: pointer;
  }
  &:before,
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: var(--Parameters-Radius-xl, 12px);
    transition: all 0.5s ease;
    height: 100%;
    pointer-events: none;
  }
  &:before {
    background: linear-gradient(252deg, #dfffd4 17.1%, #71b15a 73.37%);
    width: 100%;
  }
  &:after {
    background: linear-gradient(180deg, #66ffbe 0%, #f00 115.87%);
    width: 0;
    z-index: 0;
  }
  &:hover {
    &:before {
      width: 0;
    }
    &:after {
      width: 100%;
    }
  }
  span {
    color: #000;
    font-family: Poppins;
    font-size: 14.189px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.47px; /* 130.169% */
    position: relative;
    z-index: 1;
    pointer-events: none;
  }
`;
export const ButtonStyle2 = styled.button`
  position: relative;
  width: calc(100% - 20px);
  min-height: 60px;
  margin: 0 auto;
  border-radius: 10px;
  background: rgb(15 15 15 / 70%);
  background-blend-mode: luminosity;
  box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
  backdrop-filter: blur(50px);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  border: none;
  button {
    background-color: unset;
    border: unset;
    cursor: pointer;
    outline: none;
  }
  ${BorderAnimation} {
    visibility: hidden;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
    ${BorderAnimation} {
      visibility: visible;
    }
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(79.19% 79.19% at 49.22% 92.12%, #37373C 0%, #000 100%);
    left: 0;
    top: 0;
    opacity: 0;
    transition: all .15s linear;
  }
  span {
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    z-index: 1;
    pointer-events: none;
    display: flex;
    align-items: center;
  }
`;
export const ButtonCommon = styled.a`
  position: relative;
  border-radius: var(--Parameters-Radius-xl, 14px);
  border: 1px solid #a2eaa1;
  box-shadow: 0px 0px 18.108px 0px rgba(255, 255, 255, 0.06) inset;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  button {
    background-color: unset;
    border: unset;
    cursor: pointer;
  }
  &:before,
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: var(--Parameters-Radius-xl, 12px);
    transition: all 0.5s ease;
    height: 100%;
    pointer-events: none;
  }
  &:before {
    background: linear-gradient(252deg, #dfffd4 17.1%, #71b15a 73.37%);
    width: 100%;
  }
  &:after {
    background: linear-gradient(180deg, #66ffbe 0%, #f00 115.87%);
    width: 0;
    z-index: 0;
  }
  &:hover {
    &:before {
      width: 0;
    }
    &:after {
      width: 100%;
    }
  }
  span {
    color: #000;
    font-family: Poppins;
    font-size: 14.189px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.47px; /* 130.169% */
    position: relative;
    z-index: 1;
    pointer-events: none;
  }
`;

export const TitleCommon = styled.h1`
  font-size: 36px;
  line-height: 44.747px; /* 124.372% */
  white-space: pre-line;
  background: linear-gradient(0deg, #b6c2f1 0%, #ebefff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 769px) {
    font-size: 32px;
  }
`;

export const CardStyle = styled.div`
  border-radius: 20px;
  border: 1px solid rgba(161, 174, 229, 0.16);
  overflow: hidden;
  position: relative;
  z-index: 0;
  opacity: 0;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  img {
    transition: all 0.3s;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
  ${BorderAnimation} {
    z-index: 5;
  }
  &.ai-metafi {
    opacity: 1 !important;
  }
  @media screen and (max-width: 1023px) {
    opacity: 1;
  }
`;
export const HomeSpline = styled.div`
  position: relative;
  height: 150vh;
`;
export const HomeSplineWrapper = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  transition: all 0.35s;
  .spline {
    position: absolute;
    left: 0;
    top: 0;
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
`;

//BlockLast
export const BlockLast = styled.div`
  position: relative;
  padding-bottom: 216px;
  z-index: 1;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(${bg}) center / cover no-repeat;
    z-index: -1;
    pointer-events: none;
  }
  @media screen and (max-width: 1023px) {
    padding-bottom: 0;
  }
`;
