import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import { HomeWrapper } from "./styled";
import HeaderPhase2 from "../../components/HeaderPhase2";
import NotiCommon from "../../components/Common/ModalNoti";

const HomeLayout = () => {
  return (
    <HomeWrapper>
      <NotiCommon />
      <HeaderPhase2 />
      <Outlet />
      <Footer />
    </HomeWrapper>
  );
};

export default HomeLayout;
