import { Suspense, lazy, ElementType } from "react";
import LoadingCube from "../components/LoadingCube";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingCube />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const DashboardHome = Loadable(
  lazy(() => import("../page/Dashboard/Home"))
);
export const Account = Loadable(
  lazy(() => import("../page/Dashboard/Account"))
);
export const Affiliate = Loadable(
  lazy(() => import("../page/Dashboard/Affiliate"))
);
export const Bonus = Loadable(lazy(() => import("../page/Dashboard/Bonus")));
export const Swap = Loadable(lazy(() => import("../page/Dashboard/Swap")));
export const History = Loadable(
  lazy(() => import("../page/Dashboard/History"))
);
export const Invest = Loadable(lazy(() => import("../page/Dashboard/Invest")));
export const CombineWallet = Loadable(
  lazy(() => import("../page/Dashboard/Wallet/Combine"))
);
export const WalletAIRDROP = Loadable(
  lazy(() => import("../page/Dashboard/Wallet/AIRDROP"))
);
export const Support = Loadable(
  lazy(() => import("../page/Dashboard/Support"))
);
