import styled from "styled-components";
import { TextCommon, TitleCommon } from "../../../Layout/styled";

export const FaqWrapper = styled.div`
  padding: 60px 0;
  margin-top: 60px;
  ${TitleCommon} {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 1023px) {
    padding: 0px 20px;
    overflow: hidden;
  }
`;
export const FaqWrapperInner = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1160px;
  margin: 0 auto;
  @media screen and (max-width: 1023px) {
    display: block;
  }
  @media screen and (max-width: 1023px) {
    margin-bottom: 60px;
  }
`;
export const FaqLeft = styled.div`
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .title {
    margin-bottom: 58px;
    button {
      margin-bottom: 22px;
    }
    @media screen and (max-width: 769px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h1,
      p {
        text-align: center;
      }
    }
  }
  .content {
    p {
      color: #CBCBCB;
      font-family: Inter;
      font-size: 13.75px;
      font-style: normal;
      margin-bottom: 26px;
    }
    button {
      width: 128.436px;
      height: 41.018px;
    }
    @media screen and (max-width: 769px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 44px;
      h1,
      p {
        text-align: center;
      }
    }
  }
  ${TextCommon} {
    @media screen and (max-width: 1023px) {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 1023px) {
    max-width: none;
    gap: 20px;
    margin-bottom: 30px;
  }
`;
export const FaqListButton = styled.ul`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: auto;
  a {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    padding: 0 18px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  li {
    border-radius: 10.289px;
    &:nth-child(1) {
      border: 1px solid #b692f6;
      a {
        color: #b692f6;
      }
    }
    &:nth-child(2) {
      border: 1px solid #fdb022;
      a {
        color: #fdb022;
      }
    }
    &:nth-child(3) {
      border: 1px solid #32d583;
      a {
        color: #32d583;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    margin-bottom: 20px;
    a {
      font-size: 15px;
    }
  }
`;
export const FaqAnswer = styled.div<{ clicked?: boolean }>`
  color: #91a0d7;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.224px;
`;
export const FaqList = styled.ul<{ clicked?: boolean }>`
  flex-shrink: 0;
  max-width: 550px;
  width: 100%;
  @media screen and (max-width: 1023px) {
    max-width: 100%;
  }
  & > li {
    position: relative;
    padding: 11px;
    border-bottom: 1px solid rgba(161, 174, 229, 0.2);
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    ${TextCommon} {
      position: relative;
      padding-right: 30px;
      color: #d3dcff;
      font-size: 18px;
      line-height: 23.865px;
      letter-spacing: 0.224px;
      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -12px;
        background: url("/assets/icons/icon-plus.png") no-repeat center / 100%
          auto;
        width: 32px;
        height: 32px;
        transition: transform 0.25s;
      }
    }
    &.active {
      ${FaqAnswer} {
        margin-top: 18px;
        opacity: 1;
      }
      ${TextCommon}::before {
        transform: rotate(180deg);
        background: url("/assets/icons/icon-sub.png") no-repeat center / 100%
          auto;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    & > li {
      width: 100%;
    }
  }
  @media screen and (max-width: 743px) {
    margin-bottom: 0;
    & > li {
      padding: 15px;
      ${TextCommon} {
        font-size: 15px;
        &::before {
          width: 24px;
          height: 24px;
          margin-top: -12px;
        }
      }
      &.active {
        ${TextCommon}::before {
          transform: rotate(-180deg);
        }
      }
    }
  }
`;
