import { Outlet, useNavigate } from "react-router-dom";
import { DashboardBody, DashboardContainer, DashboardContent } from "./styled";
import Sidebar from "../../components/Sidebar";
import HeaderDashboard from "../../components/Header/Dashboard";
import { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { dispatch } from "../../redux/store";
import {
  IsLoadingRedux,
  UserOverview,
  getTokenPrice,
} from "../../redux/slices/user";
import { useSelector } from "react-redux";
import KYCModal from "../../components/KYC";
import NotiCommon from "../../components/Common/ModalNoti";

const GET_USER = gql`
  query {
    user {
      email
      refCode
      phoneNumber
      accountID
      secret
      createdAt
      children
      levelAMFI
      packUSDT
      verified2FA
      totalSales
      totalSalesLeft
      totalSalesRight
      totalLeftDirectLine
      totalRightDirectLine
      directCommission
      rightReserve
      leftReserve
      totalRevenue
      modeTree
      bonusVerify
      totalBonus
      totalWeakBranch
      totalStaking
      maxOutUSDT
      totalDepositAMFI
      totalDepositUSDT
      parentEmail
      wallet {
        aimetafi {
          address
          balance
          transaction {
            amount
            id
            hash
            note
            createdAt
            transactionType
          }
        }
        usdt {
          address
          balance
          transaction {
            amount
            id
            hash
            note
            createdAt
            transactionType
          }
        }
        point {
          address
          balance
          transaction {
            amount
            id
            hash
            note
            createdAt
            transactionType
          }
        }
        revenue {
          address
          balance
          transaction {
            amount
            id
            hash
            note
            createdAt
            transactionType
          }
        }
        bonus {
          address
          balance
          transaction {
            amount
            id
            hash
            note
            createdAt
            transactionType
          }
        }
      }
      affiliate {
        displayName
        email
        createdAt
        branch
        accountID
        packAMFI
        packUSDT
        parentID
        children
        levelAMFI
        refCode
      }
      transactionRevenue {
        amount
        id
        note
        createdAt
        transactionType
      }
      packInvestingAMFI {
        price
        rate
        priceAMFI
        expireDate
        packDatetime
        paymentCurrency
      }
    }
    tickets {
      id
      subject
      status
      messages {
        text
        author
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    kyc {
      status
      accountID
      frontURL
      backURL
      documentID
      country
    }
    settings {
      tag
      config
    }
  }
`;

const GET_PRICE_TOKEN: any = gql`
  query {
    settings {
      tag
      config
    }
  }
`;

const DashboardLayout = () => {
  const [collapse, setCollapse] = useState(false);
  const [openHamburger, setOpenHamburger] = useState(false);
  const navigate = useNavigate()

  // Get user
  const { data, refetch, loading: isFetchUser } = useQuery(GET_USER);

  // Reload GET_USER when submit or update data
  const { isLoading }: any = useSelector((state: any) => state.user);
  useEffect(() => {
    if (isLoading) {
      refetch();
      dispatch(IsLoadingRedux(false));
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isFetchUser) {
      dispatch(UserOverview(data));
    }
  }, [isFetchUser]);

  useEffect(() => {
    if (!data && !isFetchUser) {
      localStorage.removeItem("idToken");
      navigate("/auth/login")
    }
  },[isFetchUser])

  // Get Token Price
  const { data: TokenPrice } = useQuery(GET_PRICE_TOKEN, {
    fetchPolicy: "network-only",
  });
  const rateAMFI = TokenPrice?.settings.find(
    (e: any) => e.tag === "rateAMFI"
  ).config;
  useEffect(() => {
    if (TokenPrice) {
      dispatch(getTokenPrice(rateAMFI));
    }
  }, [TokenPrice]);

  return (
    <DashboardContainer>
      <NotiCommon />
      <Sidebar
        setOpenHamburger={setOpenHamburger}
        openHamburger={openHamburger}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <DashboardContent>
        <HeaderDashboard
          user={data?.user}
          setOpenHamburger={setOpenHamburger}
          openHamburger={openHamburger}
          collapse={collapse}
        />
        <DashboardBody className={collapse ? "collapse" : ""}>
          <Outlet />
        </DashboardBody>
      </DashboardContent>
      <KYCModal />
    </DashboardContainer>
  );
};

export default DashboardLayout;
