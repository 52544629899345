import { useLocation } from "react-router-dom";
import {
  HDLeft,
  HDPageTitle,
  HDRight,
  HDWrapper,
  HeaderDashboardContainer,
} from "./styled";
import ProfileCommon from "../../Common/Profile";
import { useContext, useEffect } from "react";
import { ButtonHamburger, WrapperHamburger } from "../styled";
import { ContextProviderWrapper } from "../../Context";
import { SidebarLogo } from "../../Sidebar/styled";
import { Link } from "react-router-dom";
import sidebar_logo from "../../../assets/Sidebar/sidebar_logo.png";
import ChangeLanguage from "../../ChooseLanguage";
import { HeaderLanguage } from "../../ChooseLanguage/styled";
import { useTranslation } from "react-i18next";

const HeaderDashboard = ({
  user,
  collapse,
  openHamburger,
  setOpenHamburger,
}: any) => {
  const { pathname } = useLocation();
  const { isDesktop, isMobile } = useContext(ContextProviderWrapper)!;
  const handleOpenHamburger = () => {
    setOpenHamburger(!openHamburger);
  };

  useEffect(() => {
    if (openHamburger) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [openHamburger]);

  const { t } = useTranslation();

  const convert = pathname.split("/");

  return (
    <HeaderDashboardContainer className={collapse ? "collapse" : ""}>
      <HDWrapper>
        <HDLeft>
          {isDesktop ? (
            <>
              <HDPageTitle>
                {pathname === "/wallet/airdrop" ? (
                  `${t("Airdrop Wallet")}`
                ) : (
                  <>{`${t(
                    `${
                      convert[1].charAt(0).toUpperCase() +
                      convert[1].slice(1).toLowerCase()
                    }`
                  )}`}</>
                )}
              </HDPageTitle>
            </>
          ) : (
            <>
              <SidebarLogo>
                <Link to="/">
                  <img className="normal" src={sidebar_logo} alt="logo" />
                </Link>
              </SidebarLogo>
            </>
          )}
        </HDLeft>
        {!isMobile && (
          <HeaderLanguage>
            <ChangeLanguage />
          </HeaderLanguage>
        )}
        <HDRight>
          <ProfileCommon user={user} />
        </HDRight>
        {!isDesktop && (
          <WrapperHamburger>
            <ButtonHamburger
              className={openHamburger ? "active" : ""}
              onClick={() => {
                handleOpenHamburger();
              }}
            >
              <span></span>
              <span></span>
            </ButtonHamburger>
          </WrapperHamburger>
        )}
      </HDWrapper>
    </HeaderDashboardContainer>
  );
};

export default HeaderDashboard;
