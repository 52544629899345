import { styled } from "styled-components";
import { ButtonStyle } from "../../page/Homev2/styled";

export const ExpressYourLimitationStyle = styled.div<{ convertValue?: any }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  z-index: 5;
  &::before {
    content: "";
    position: ${({ convertValue }) => convertValue > 0 && convertValue < 1 ? "fixed" : "absolute"};
    top: 200px;
    left: 0;
    background: url("/assets/images/Challenge/bg.png") center / cover no-repeat;
    width: 100%;
    height: 1000px;
    transition: all .15s linear;
  }
  @media screen and (max-width: 1023px) {
    height: auto;
  }
  h1 {
    margin: 19px auto;
    text-align: center;
  }
  .sm {
    display: none;
  }
  p {
    color: #b4b4b4;
    text-align: center;
    font-family: Inter;
    font-size: 10.627px;
    max-width: 602px;
    width: 100%;
    margin: 0 auto;
  }
  .container-ai {
    margin-top: 30px;
    .ai-row {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 21px;
      .title {
        h3 {
          color: #ecf0ff;
          text-align: center;
          font-family: Inter;
          font-size: 16.885px;
          font-style: normal;
          font-weight: 400;
          line-height: 24.014px; /* 142.222% */
        }
        p {
          color: #a0aee5;
          text-align: center;
          font-family: Inter;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20.405px; /* 160.793% */
          letter-spacing: 0.268px;
        }
      }
      .mix-text-chat,
      .mix-video {
        display: none !important;
      }
      .ai-text-chat {
        width: 218px;
        height: 424px;
        background: #0c061e;
        .image {
          width: 100%;
          height: 70%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .diagonal-ai-text {
          background: #0c061e;
          overflow: hidden;
          transform: skewY(-20deg);
          padding-top: 50px;
          padding-bottom: 40px;
          margin-top: -40px;
          .title {
            transform: skewY(20deg);
            margin-top: 15px;
          }
        }
      }
      .ai-gen-image {
        max-width: 430px;
        height: 200px;
        display: flex;
        background-color: #0c061e;
        .diagonal-ai-gen-image {
          position: relative;
          flex-shrink: 0;
          &::after {
            position: absolute;
            content: "";
            width: 231px;
            height: 300px;
            background: #0c061e;
            transform: skewY(-68deg);
            top: -15px;
            right: -40px;
            z-index: 1;
          }
          .title {
            position: relative;
            z-index: 2;
            width: 100%;
            padding-top: 30px;
            padding-left: 20px;
            h3 {
              text-align: left;
              letter-spacing: -0.3px;
            }
            p {
              text-align: left;
              font-size: 12px;
              letter-spacing: 0;
            }
          }
        }
        .image {
          margin-left: -50px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .ai-metafi {
        background-image: url("/assets/background/bg-ai-metafi.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 430px;
        height: 200px;
        margin-top: 21px;
        transition: all.3s;
        position: relative;
        .image {
          width: 256px;
          margin: 0 auto;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          img {
            width: 100%;
            height: auto;
          }
        }
        &::after {
          content: "";
          position: absolute;
          z-index: 2;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          transition: all.3s;
          background-size: cover;
          background-repeat: no-repeat;
          background-image: url("/assets/background/bg-navbar.png");
          opacity: 0;
        }
        &:hover {
          &::after {
            opacity: 1;
          }
        }
      }
      .ai-video {
        width: 218px;
        height: 424px;
        background: linear-gradient(180deg, #d6c7ff 0%, #b642ff 100%);
        border: none;
        .diagonal-ai-video {
          background: linear-gradient(180deg, #d6c7ff 0%, #d083fc 100%);
          overflow: hidden;
          transform: skewY(20deg);
          margin-bottom: -40px;
          position: relative;
          z-index: 2;
        }
        .title {
          transform: skewY(-20deg);
          padding: 40px 0;
          h3 {
            color: #030515;
            font-size: 20px;
          }
          h4 {
            color: #030515;
            text-align: center;
            font-family: Inter;
            font-size: 16.081px;
            font-style: normal;
            font-weight: 400;
            line-height: 24.014px;
          }
          p {
            color: #030515;
            font-size: 12px;
          }
        }
        .image {
          position: relative;
          z-index: 1;
          img {
            width: 100%;
          }
        }
      }
      .ai-voice {
        display: flex;
        width: 50%;
        height: 220px;
        margin-top: 21px;
        .image {
          width: 50%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .title {
          width: 50%;
          display: flex;
          flex-direction: column;
          position: relative;
          z-index: 2;
          background-color: #0c061e;
          padding: 37px 0;
          padding-left: 15px;
          &::after {
            position: absolute;
            content: "";
            width: 231px;
            height: 200px;
            background: #0c061e;
            transform: skewY(70deg) rotate(55deg);
            z-index: 1;
            left: -98px;
          }
          h3 {
            text-align: left;
            position: relative;
            z-index: 3;
          }
          .description {
            position: relative;
            z-index: 2;

            p {
              font-size: 11px;
              text-align: left;
            }
          }
        }
      }
      .ai-bot-trading {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 21px;
        .title {
          &::after {
            position: absolute;
            content: "";
            width: 231px;
            height: 230px;
            background: #0c061e;
            transform: skewY(-75deg);
            z-index: 1;
            left: 95px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .container-ai {
      margin-top: 100px;
    }
  }
  @media screen and (max-width: 830px) {
    .container-ai {
      .ai-row {
        .ai-text-chat,
        .ai-video {
          max-width: 200px;
        }
        .ai-bot-trading {
          .title {
            &::after {
              width: 170px;
              height: 250px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    p {
      font-size: 15px;
    }
    .container-ai {
      margin-top: 50px;
      .ai-row {
        flex-direction: column;
        .ai-text-chat,
        .ai-gen-image,
        .ai-bot-trading,
        .ai-metafi,
        .ai-video,
        .ai-voice {
          width: 100%;
          max-width: 100%;
          p {
            font-size: 14px !important;
          }
          .image {
            margin-left: 0;
          }
        }
        .ai-metafi {
          .image {
            margin: 0 auto;
            margin-bottom: 10px;
          }
        }
        .ai-text-chat,
        .ai-video {
          display: none !important;
        }
        .mix-text-chat,
        .mix-video {
          display: flex !important;
          .image {
            height: 200px;
          }
        }
        .ai-center {
          order: -1;
          display: flex;
          flex-direction: column-reverse;
          gap: 20px;
        }
        .ai-text-chat {
          display: flex;
          max-width: 430px;
          height: 200px;
          display: flex;
          background-color: #0c061e;
          .diagonal-ai-text {
            position: relative;
            flex-shrink: 0;
            &::after {
              position: absolute;
              content: "";
              width: 231px;
              height: 300px;
              background: #0c061e;
              transform: skewY(-68deg);
              top: -15px;
              right: -40px;
              z-index: 1;
            }
            .title {
              position: relative;
              z-index: 2;
              width: 100%;
              padding-top: 30px;
              padding-left: 20px;
              h3 {
                text-align: left;
                letter-spacing: -0.3px;
              }
              p {
                text-align: left;
                font-size: 12px;
                letter-spacing: 0;
              }
            }
          }
          .image {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .ai-gen-image {
          flex-direction: column-reverse;
          height: 260px;
          .diagonal-ai-gen-image {
            .title {
              padding-top: 0;
              padding-bottom: 20px;
              padding-left: 0;
              h3,
              p {
                text-align: center;
                max-width: 100%;
              }
            }
            &::after {
              width: 120%;
              height: 111px;
              background: #0c061e;
              transform: skewY(-6deg);
              top: -24px;
              right: 0px;
              z-index: 1;
            }
          }
        }
        .ai-voice {
          .image {
            width: calc(100% - 150px);
          }
          .title {
            width: 150px;
            justify-content: flex-end;
            align-items: flex-end;
            position: relative;
            z-index: 2;
            background-color: #0c061e;
            padding: 37px 0;
            padding-right: 20px;
            &::after {
              width: 600px;
              height: 250px;
              transform: skewY(-52deg);
              top: -64px;
              left: -250px;
            }
            .description {
              width: 200px;
              p {
                text-align: right;
                font-size: 14px !important;
              }
            }
          }
        }
        .ai-bot-trading {
          margin-top: 0;
          .title {
            padding-left: 20px;
            padding-right: 0;
            align-items: flex-start;
            .description {
              p {
                text-align: left;
              }
            }
            &::after {
              transform: skewY(61deg);
              left: -180px;
              top: -97px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 481px) {
    .container-ai {
      .ai-row {
        flex-direction: column;
        .ai-text-chat {
          display: flex;
          max-width: 300px;
          .image {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .ai-gen-image {
          .diagonal-ai-gen-image {
            &::after {
              width: 120%;
              height: 111px;
              background: #0c061e;
              transform: skewY(-6deg);
              top: -24px;
              right: 0px;
              z-index: 1;
            }
          }
        }
        .ai-voice {
          .image {
            width: calc(100% - 120px);
          }
          .title {
            width: 120px;
            &::after {
              width: 215px;
              height: 357px;
              top: 47px;
              left: -184px;
              transform: skewY(-65deg);
            }
          }
        }
        .ai-bot-trading {
          .title {
            &::after {
              width: 225px;
              height: 280px;
              top: 55px;
              left: 71px;
              transform: skewY(56deg);
            }
          }
        }
      }
    }
  }
`;
export const ExpressWrapper = styled.div`
  position: sticky;
  top: 0;
  max-width: 1160px;
  width: 100%;
  margin: 0 auto;
  ${ButtonStyle} {
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: 1023px) {
    position: relative;
    width: 100%;
  }
`;
export const ExpressBody = styled.div`
  @media screen and (max-width: 1023px) {
    position: relative;
    width: 100%;
  }
`;
export const SplineContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transform: scale(1.2);
`;
export const ChallengeContainer = styled.div`
  .swiper {
    overflow: unset;
    .swiper-button-next,
    .swiper-button-prev {
      width: 38px;
      height: 38px;
      &::after {
        content: "";
        width: 38px;
        height: 38px;
        background: no-repeat center / 100% auto;
      }
    }
    .swiper-button-prev {
      &::after {
        background-image: url("./assets/images/Challenge/prev_button.png");
      }
    }
    .swiper-button-next {
      &::after {
        background-image: url("./assets/images/Challenge/next_button.png");
      }
    }
  }
`;
export const ChallengeBlockInner = styled.div`
  position: sticky;
  top: 0;
  min-height: calc(100vh - 200px);
  transition: all .2s linear;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: var(--Text-Text-Primary, rgba(248, 248, 248, 0.95));
    font-family: "Changa One", sans-serif;
    font-size: 20px;
    margin-bottom: 20px;
  }
  p {
    color: var(--Text-Secondary, rgba(248, 248, 248, 0.7));
    font-family: Inter;
    font-size: 14px;
    margin-bottom: 30px;
    text-align: left;
  }
  a {
    display: flex;
    width: max-content;
    border-radius: 32px;
    border: 1px solid var(--stroke-25-button, rgba(255, 255, 255, 0.4));
    background: var(--Surface-Background, rgba(40, 40, 40, 0.7));
    background-blend-mode: luminosity;
    box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
    backdrop-filter: blur(50px);
    color: var(--Text-Secondary, rgba(248, 248, 248, 0.7));
    font-size: 16px;
    font-weight: 500;
    padding: 12px 32px;
  }
  .block-left {
    padding-right: 40px;
  }
  & > div {
    &:nth-child(2) {
      &.content-right {
        position: relative;
        width: 483px;
        flex-shrink: 0;
        &::before {
          content: "";
          position: absolute;
          width: 515px;
          height: 314px;
          margin-top: -4px;
          border-radius: 16px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 1px solid #6C6C6C;
          background: linear-gradient(180deg, #0F1210 0%, #161616 100%);
          box-shadow: 0px 0px 90.5px 0px rgba(255, 9, 13, 0.31);
          backdrop-filter: blur(50px);
          z-index: -1;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    flex-direction: column-reverse;
    position: relative;
    min-height: auto;
    & > div {
    &:nth-child(2) {
      &.content-right {
        margin-bottom: 50px;
        flex-shrink: unset;
      }
    }
    h3,
    p {
      text-align: center;
    }
    h3 {
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 15px;
    }
    }
    a {
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0 10px;
    padding-left: 15px;
    .block-left {
      padding-right: 0;
    }
    & > div {
      &:nth-child(2) {
        &.content-right {
          width: 100%;
          margin-bottom: 20px;
          &::before {
            border: none;
            background: transparent;
            width: 290px;
            height: 160px;
          }
        }
      }
    }
  }
`;
