import { getAuth, signInWithCustomToken } from "firebase/auth";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const LoginToken = () => {
  // handle Login with Token
  const auth: any = getAuth();
  const { id }: any = useParams();
  const navigate = useNavigate();
  const handleLogin = async () => {
    try {
      const userCredential: any = await signInWithCustomToken(auth, id);
      const idToken = await auth.currentUser.getIdToken(true);
      if (userCredential.user) {
        localStorage.setItem("idToken", idToken);
        if (!userCredential.user.emailVerified) {
          navigate("/auth/verify");
        } else {
          navigate("/dashboard");
        }
      }
    } catch (error) {
      navigate("/auth/login");
      console.error(error);
      toast.error("Error");
    }
  };

  useEffect(() => {
    if (id) {
      handleLogin();
    }
  }, [id]);

  return <div></div>;
};

export default LoginToken;
