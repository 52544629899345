import { instance } from "../instance";

export const AIMETAFI_API = {
  getPriceToken() {
    return instance.get(`api/token/price`);
  },
  getWalletOwner() {
    return instance.get(`api/ReceiveAddress`);
  },
  buyAMFI(value: any) {
    return instance.post(`api/user/investment`, value)
  },
  login(value: any) {
    return instance.post(`api/login`, value)
  },
  verify(data: any) {
    return instance.post<any>(`https://chat.aimetafi.io/api/user/verify`, data);
  },
  sendMess(data: any) {
    return instance.post<any>(`https://chat.aimetafi.io/api/ai/chat`, data);
  },
  enableBuyICO() {
    return instance.get(`api/IcocheckEnable`)
  }
};
