import { styled } from "styled-components";
import { ButtonStyle } from "../../page/Homev2/styled";

export const VersionAndMissionStyle = styled.div`
  position: relative;
  background-color: #0d0d0d;
  margin-top: -60px;
  z-index: 1;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1017px;
    width: 100%;
    background: linear-gradient(90deg, #0d0d0d 0%, #9b9b9b 45.1%, #0d0d0d 100%);
    height: 1px;
    background-color: red;
  }
  ${ButtonStyle} {
    display: flex;
    justify-content: center;
  }
  h1 {
    text-align: center;
    margin-top: 26px;
    font-size: 36px;
    line-height: 44.747px;
    background: linear-gradient(0deg, #b6c2f1 0%, #ebefff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 30px;
  }
  .btn-we-see {
    button {
      display: flex;
      padding: 5.966px 14.916px 5.959px 14.916px;
      justify-content: center;
      align-items: center;
      gap: 2.983px;
      border-radius: 44.747px;
      background: rgba(188, 200, 255, 0.01);
      box-shadow: 0px 0px 29.831px 0px rgba(153, 146, 217, 0.23) inset;
      backdrop-filter: blur(5px);
      border: none;
      span {
        color: #a1aee5;
        text-align: center;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.254px;
      }
    }
  }
  .version-mission {
    display: flex;
    justify-content: center;
    gap: 21px;
    width: 100%;
    position: relative;
    .our-item {
      max-width: 442px;
      border-radius: 20px;
      padding: 32px 28px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      position: relative;
      &:nth-child(1) {
        &:after {
          position: absolute;
          content: "";
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          width: 2px;
          height: 214px;
          background: url("/assets/images/Version/bg_line_01.png");
        }
      }
      .image {
        position: relative;
        left: -27px;
      }
      h3 {
        color: #fff;
        font-family: Inter;
        font-size: 21px;
        font-weight: 700;
      }
      p {
        color: #ededed;
        font-family: Inter;
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 1023px) {
    padding: 20px 15px;
    .version-mission {
      .our-item {
        padding: 0 20px;
        &:nth-child(1) {
          &:after {
            content: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      margin-top: 12px;
      margin-bottom: 30px;
    }
    .version-mission {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 481px) {
    .version-mission {
      .our-item {
        max-width: 100%;
      }
    }
  }
`;
