import styled from "styled-components";
import { SelectContainer } from "../Common/Select/styled";

export const HeaderLanguage = styled.div`
  margin-left: auto;
  margin-right: 20px;
  ${SelectContainer} {
    width: 150px;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 0;
    width: calc(100% - 40px);
    margin: 0 auto;
    ${SelectContainer} {
    width: 100%;
  }
  }
`;
