import { useEffect, useState } from "react";
import ModalNotification from "../../ModalNoti";

const NotiCommon = () => {
  const [closeNoti, setCloseNoti] = useState(false);

  useEffect(() => {
    if (!closeNoti) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [closeNoti]);

  return (
    <>
      {closeNoti ? (
        <></>
      ) : (
        <ModalNotification
          closeFunction={() => {
            setCloseNoti(true);
          }}
        />
      )}
    </>
  );
};

export default NotiCommon;
