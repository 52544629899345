import { Link } from "react-router-dom";
import {
  ButtonHamburger,
  HeaderLogo,
  WrapperHamburger,
} from "../Header/styled";
import { HeaderNavigation, HeaderStyled } from "./styled";

import logo from "../../assets/Header/logo.png";
import { linkData } from "../Header";
import { HashLink } from "react-router-hash-link";
import { useContext, useEffect, useState } from "react";
import { ContextProviderWrapper } from "../Context";
import { NavLink } from "react-router-dom";
import StarUp from "../StarUp";
import { useTranslation } from "react-i18next";
import { HeaderLanguage } from "../ChooseLanguage/styled";
import ChangeLanguage from "../ChooseLanguage";

export default function HeaderPhase2() {
  const { t } = useTranslation();
  const [openHamburger, setOpenHamburger] = useState(false);
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const [IsScroll, setIsScroll] = useState(false);
  const isPreloader = localStorage.getItem("isPreloader");
  const idToken = localStorage.getItem("idToken");
  useEffect(() => {
    const onScroll = () => setIsScroll(window.scrollY > 100);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (openHamburger) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [openHamburger]);

  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (isPreloader !== "true") {
      setTimeout(() => {
        setHidden(true);
      }, 500);
    } else {
      setHidden(false);
    }
  }, []);

  return (
    <HeaderStyled
      style={{
        transform: hidden
          ? "translateY(0)"
          : isPreloader === "true"
          ? "translateY(0)"
          : "translateY(-100px)",
        opacity: hidden ? "1" : isPreloader === "true" ? "1" : "0",
        visibility: hidden
          ? "visible"
          : isPreloader === "true"
          ? "visible"
          : "hidden",
        background: IsScroll ? "rgba(3, 5, 21, 0.11)" : "rgba(3, 5, 21, 0.11)",
      }}
    >
      <div className="container">
        <HeaderLogo>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </HeaderLogo>
        <div
          className="navbar-desktop"
          style={{
            transform: isDesktop
              ? "unset"
              : !isDesktop && openHamburger
              ? "translateY(66px)"
              : "translateY(66px)",
            height:
              !isDesktop && openHamburger
                ? "calc(100vh - 50px)"
                : isDesktop
                ? " auto"
                : "0",
            overflow: "hidden",
            visibility:
              !isDesktop && openHamburger
                ? "visible"
                : isDesktop
                ? "visible"
                : "hidden",
          }}
        >
          <HeaderNavigation>
            {/* {!isDesktop && (
          <HeaderLanguage>
            <ChangeLanguage />
          </HeaderLanguage>
        )} */}
            <ul
              className="header-content"
              style={{
                height:
                  !isDesktop && openHamburger
                    ? "calc(100vh - 50px)"
                    : isDesktop
                    ? " auto"
                    : "0",
              }}
            >
              {linkData.map((item: any, index: number) => {
                return (
                  <>
                    <li
                      style={{
                        opacity:
                          !isDesktop && openHamburger
                            ? "1"
                            : isDesktop
                            ? " 1"
                            : "0",
                        transform:
                          !isDesktop && openHamburger
                            ? "translateY(0)"
                            : isDesktop
                            ? " translateY(0)"
                            : "translateY(-30px)",
                        transitionDelay:
                          !isDesktop && openHamburger
                            ? item.delay
                            : isDesktop
                            ? "unset"
                            : "unset",
                      }}
                      key={index}
                    >
                      {item.scrollTo ? (
                        <HashLink
                          smooth
                          to={`/${item.href}`}
                          onClick={() => {
                            setOpenHamburger(false);
                          }}
                        >
                          {`${t(`${item.title}`)}`}
                        </HashLink>
                      ) : (
                        <>
                          {item.externalLink ? (
                            <>
                              <a
                                key={index}
                                target="_blank"
                                rel="noreferrer"
                                href={item.href}
                              >
                                {`${t(`${item.title}`)}`}
                              </a>
                            </>
                          ) : (
                            <>
                              <NavLink
                                key={index}
                                onClick={() => setOpenHamburger(false)}
                                to={item.href}
                              >
                                {`${t(`${item.title}`)}`}
                              </NavLink>
                            </>
                          )}
                        </>
                      )}
                    </li>
                    {/* {isDesktop && (
                      <>
                        {index < linkData.length - 1 && (
                          <div className="dot-link"></div>
                        )}
                      </>
                    )} */}
                  </>
                );
              })}
            </ul>
          </HeaderNavigation>
          {/* {isDesktop && (
            <HeaderLanguage>
              <ChangeLanguage />
            </HeaderLanguage>
          )} */}
          {isDesktop && (
            <Link
              style={{
                marginLeft: "auto",
              }}
              to={idToken ? "/dashboard" : "/auth/login"}
              className="btn-staking"
            >
              <button
                style={{
                  position: "relative",
                  color: "#fff",
                  overflow: "hidden",
                  width: "max-content",
                }}
              >
                {/* <StarUp /> */}
                <span>{idToken ? `${t("Dashboard")}` : `${t("Try Now")}`}</span>
              </button>
            </Link>
          )}
        </div>
        {!isDesktop && (
          <>
            <Link
              to={idToken ? "/dashboard" : "/auth/login"}
              className="btn-staking"
            >
              <button>
                <span>{idToken ? `${t("Dashboard")}` : `${t("Staking")}`}</span>
                {isDesktop && (
                  <>
                    <img
                      src="/assets/icons/icon-go-staking.png"
                      alt="icon-go-staking"
                    />
                  </>
                )}
              </button>
            </Link>
            <WrapperHamburger
              onClick={() => {
                setOpenHamburger(!openHamburger);
              }}
            >
              {t("Menu")}
              <ButtonHamburger className={openHamburger ? "active" : ""}>
                <span></span>
                <span></span>
              </ButtonHamburger>
            </WrapperHamburger>
          </>
        )}
      </div>
    </HeaderStyled>
  );
}
