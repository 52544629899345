import BoxChat from "../../components/BoxChat";
import { AiChatStyle } from "./styled";

export default function AiChat() {
  return (
    <AiChatStyle>
      <BoxChat />
    </AiChatStyle>
  )
}
