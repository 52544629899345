import { createContext, useState, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

interface StoreContextValue {
  isDesktop: any;
  isTablet: any;
  isMobile: any;
  setValueProvider: any;
  valueProvider: any;
  setValueLetter: any;
  valueLetter: any;
  changeLanguage: any;
}
interface Props {
  children: ReactNode;
}
export const ContextProviderWrapper = createContext<StoreContextValue | null>(
  null
);
export const ContextProvider: React.FC<Props> = ({ children }: Props) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isTablet = useMediaQuery({ maxWidth: 1023, minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [valueProvider, setValueProvider] = useState(false);
  const [valueLetter, setValueLetter] = useState(false);
  //translator
  const { i18n } = useTranslation();
  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const languageState: any = localStorage.getItem("languageState")
    i18n.changeLanguage(languageState);
  }, []);

  return (
    <ContextProviderWrapper.Provider
      value={{
        isDesktop,
        isTablet,
        isMobile,
        setValueProvider,
        valueProvider,
        setValueLetter,
        valueLetter,
        changeLanguage
      }}
    >
      {children}
    </ContextProviderWrapper.Provider>
  );
};
