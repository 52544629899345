import styled from "styled-components";

export const HomeWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const BackgroundBanner = styled.div`
  position: relative;
  margin-bottom: 30px;
  &:before {
    position: absolute;
    content: "";
    top: -440px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 1135px;
    background: url('/assets/images/Banner/bg.png') center / cover no-repeat;
  }
  .bg-1 {
    position: relative;
    max-width: 1160px;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .bg-2 {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    .bg-1 {
      min-height: auto;
    }
  }
`;
