import { useContext, useEffect, useState } from "react";
import { BackgroundBanner } from "../../Layout/Home/styled";
import { ButtonStyle } from "../../page/Homev2/styled";
import { BoxChatStyle, LoadingRobot } from "./styled";
import { useAccount, useSignMessage } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { AIMETAFI_API } from "../../services/api";
import { PresaleCW } from "../../page/BuyAMFI/styled";
import { shortenAddress } from "../../utils/addressUser";
import Typewriter from "typewriter-effect";
import toast from "react-hot-toast";
import robot from "../../assets/gif/robot.gif";
import { ContextProviderWrapper } from "../Context";
import { useTranslation } from "react-i18next";

const BoxChat = () => {
  const {t} = useTranslation()
  const { open } = useWeb3Modal();
  const { address, isDisconnected } = useAccount();
  const { data: signMessageData, signMessage }: any = useSignMessage();
  const {isMobile} = useContext(ContextProviderWrapper)!
  const [message, setMessage] = useState<any>("");
  const [listOfMessage, setListOfMessage] = useState<any>([]);
  const [timeNow, setTimeNow] = useState<any>("");
  const [history, setHistory] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const verifyMessage = "Verify";

  const handleInputChange = (e: any) => {
    e.preventDefault();
    const { value } = e.target;
    setMessage(value);
  };

  // Handle verify
  const [verifyStatus, setVerifyStatus] = useState(false);
  const handleVerify = async () => {
    try {
      let data = {
        address: address,
        signature: signMessageData,
        message: verifyMessage,
      };
      await AIMETAFI_API.verify(data)
        .then((res: any) => {
          if (res && res.status === 200) {
            setVerifyStatus(true);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (signMessageData) {
      handleVerify();
    }
  }, [signMessageData]);

  useEffect(() => {
    if (address) {
      signMessage({ message: verifyMessage });
    }
    if (address && isMobile) {
      setVerifyStatus(true)
    }
  }, [address]);

  useEffect(() => {
    if (isDisconnected) {
      setVerifyStatus(false);
    }
  }, [isDisconnected]);

  const chatHeight = document.getElementById("content-chat");
  const handleSendMess = async () => {
    setTimeout(() => {
      chatHeight?.scrollTo(0, chatHeight.scrollHeight);
    }, 500);
    try {
      const prompt = {
        role: "user",
        content: message,
      };

      const data = {
        address: address,
        message: message,
      };
      setListOfMessage([...listOfMessage, prompt]);
      setIsLoading(true);
      setMessage("");
      try {
        await AIMETAFI_API.sendMess(data).then((res: any) => {
          const data = res.data.data.message;
          if (res.status === 200) {
            setTimeout(() => {
              chatHeight?.scrollTo(0, chatHeight.scrollHeight);
            }, 500);
          }
          if (data) {
            setListOfMessage((messages: any) => [
              ...messages,
              {
                role: "assistant",
                content: data,
              },
            ]);
            setHistory((history: any) => [
              ...history,
              { question: message, answer: data },
            ]);
            setIsLoading(false);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log("handleSendMess err", error);
    }
  };

  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      if (message.length > 0 && !isLoading) {
        handleDummyQuestion();
        setTimeout(() => {
          chatHeight?.scrollTo(0, chatHeight.scrollHeight);
        }, 500);
      } else if (message.length > 0 && isLoading) {
        toast.error("Please wait a sec");
      } else {
        toast.error("Please ask something");
      }
    }
  };

  const handleNewChat = () => {
    setMessage("");
    setListOfMessage([]);
    setHistory([]);
  };

  const handleSetTime = () => {
    const newDate = new Date();
    const options: any = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const date = newDate.toLocaleString("en-US", options);
    setTimeNow(date);
  };

  useEffect(() => {
    handleSetTime();
  }, []);

  const handleDummyQuestion = () => {
    switch (message) {
      case "What is AISOLARS ?":
        return (
          setListOfMessage((messages: any) => [
            ...messages,
            {
              role: "user",
              content: "What is AISOLARS ?",
            },
            {
              role: "assistant",
              content:
                "AISOLARS is not just another platform; it is an ecosystem designed to empower individuals and revolutionize the creative economy. We leverage the power of AI, DeFi, and social interaction to create a unique space where users can use Artificial Intelligence to power all in the DeFi revolution. \n\n AISOLARS Office Address:\n101 Upper Cross Street,\nPeople's Park Centre #05-29,\nSingapore 058357 \n\n Registered License Address:\n18 Sin Ming Lane, Midview City, #07-13 Singapore 573960",
            },
          ]),
          setHistory((history: any) => [
            ...history,
            {
              question: "What is AISOLARS ?",
              answer:
                "AISOLARS is not just another platform; it is an ecosystem designed to empower individuals and revolutionize the creative economy. We leverage the power of AI, DeFi, and social interaction to create a unique space where users can use Artificial Intelligence to power all in the DeFi revolution. \n\n AISOLARS Office Address:\n101 Upper Cross Street,\nPeople's Park Centre #05-29,\nSingapore 058357 \n\n Registered License Address:\n18 Sin Ming Lane, Midview City, #07-13 Singapore 573960",
            },
          ]),
          setMessage("")
        );
      case "Why Choose AISOLARS ?":
        return (
          setListOfMessage((messages: any) => [
            ...messages,
            {
              role: "user",
              content: "Why Choose AISOLARS ?",
            },
            {
              role: "assistant",
              content:
                "In a world dominated by centralized platforms and limited financial opportunities, AISOLARS stands out as a beacon of creativity, freedom, and financial empowerment. Here's why you should join us: \n\n - Own your content and data: AISOLARS is built on the principles of decentralization, giving you complete control over your creations and digital assets. \n\n - Monetize your creativity: Earn rewards for your contributions to the platform, whether it's through content creation, community engagement, or investment activities. \n\n - Be part of a vibrant community: Connect with like-minded individuals, collaborate on projects, and build meaningful relationships within the AISOLARS ecosystem. \n\n - Shape the future of the digital world: AISOLARS is more than just a platform; it's a movement. By joining us, you become an active participant in shaping the future of online interaction and financial opportunities.",
            },
          ]),
          setHistory((history: any) => [
            ...history,
            {
              question: "Why Choose AISOLARS ?",
              answer:
                "In a world dominated by centralized platforms and limited financial opportunities, AISOLARS stands out as a beacon of creativity, freedom, and financial empowerment. Here's why you should join us: \n\n - Own your content and data: AISOLARS is built on the principles of decentralization, giving you complete control over your creations and digital assets. \n\n - Monetize your creativity: Earn rewards for your contributions to the platform, whether it's through content creation, community engagement, or investment activities. \n\n - Be part of a vibrant community: Connect with like-minded individuals, collaborate on projects, and build meaningful relationships within the AISOLARS ecosystem. \n\n - Shape the future of the digital world: AISOLARS is more than just a platform; it's a movement. By joining us, you become an active participant in shaping the future of online interaction and financial opportunities.",
            },
          ]),
          setMessage("")
        );
      case "Key Features that Set AISOLARS Apart":
        return (
          setListOfMessage((messages: any) => [
            ...messages,
            {
              role: "user",
              content: "Key Features that Set AISOLARS Apart",
            },
            {
              role: "assistant",
              content:
                "- AI-Generated Content: Our AI assistants are more than just fancy spellcheckers. They can generate original content ideas, craft engaging narratives, and even design stunning visuals, freeing you to focus on your creative spark. \n\n - SocialFi Integration: AISOLARS goes beyond traditional social media by introducing SocialFi. Build meaningful connections, collaborate with like-minded individuals, and share the rewards of your collective creativity through a fair and transparent economic model. \n\n - AI Bot Crypto Exchange: Trade with confidence using our AI-powered bot that analyzes market trends and provides data-driven insights to help you make informed investment decisions. \n\n - Multichain Wallet: Store and manage your digital assets securely and seamlessly across multiple blockchain networks with our multichain wallet.",
            },
          ]),
          setHistory((history: any) => [
            ...history,
            {
              question: "Key Features that Set AISOLARS Apart",
              answer:
                "- AI-Generated Content: Our AI assistants are more than just fancy spellcheckers. They can generate original content ideas, craft engaging narratives, and even design stunning visuals, freeing you to focus on your creative spark. \n\n - SocialFi Integration: AISOLARS goes beyond traditional social media by introducing SocialFi. Build meaningful connections, collaborate with like-minded individuals, and share the rewards of your collective creativity through a fair and transparent economic model. \n\n - AI Bot Crypto Exchange: Trade with confidence using our AI-powered bot that analyzes market trends and provides data-driven insights to help you make informed investment decisions. \n\n - Multichain Wallet: Store and manage your digital assets securely and seamlessly across multiple blockchain networks with our multichain wallet.",
            },
          ]),
          setMessage("")
        );
      default:
        return handleSendMess();
    }
  };

  return (
    <BoxChatStyle>
      <BackgroundBanner style={{
        marginBottom: "0"
      }}>
        <div className="bg-1">
          <div style={{width: "100%"}} className="bg-2">
            <div className="box-container">
              <div className="new-chat new-chat-mobile">
                <ButtonStyle onClick={handleNewChat}>
                  <button>
                    <span>{t("New Chat")}</span>
                    <img
                      src="/assets/icons/icon-go-staking.png"
                      alt="icon-go-staking"
                    />
                  </button>
                </ButtonStyle>
              </div>
              <div className="box">
                <div className="box-header">
                  <div className="logo">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <div className="image" style={{
                        maxWidth: '150px'
                      }}>
                        <img src="/assets/icons/logo-v2.png" alt="logo-v2" />
                      </div>
                      <ButtonStyle>
                        <button>
                          <span>{t("Artificial Intelligence")}</span>
                          <img
                            src="/assets/icons/icon-light-purple.png"
                            alt="light-purple"
                          />
                        </button>
                      </ButtonStyle>
                    </div>
                    <PresaleCW
                      onClick={() => {
                        open();
                      }}
                    >
                      {address ? shortenAddress(address) : `${t("Connect Wallet")}`}
                    </PresaleCW>
                  </div>
                </div>
                <div className="box-content">
                  <div className="form-chat">
                    <div id="content-chat" className="content-chat">
                      <p className="last-time-send-mess">{timeNow}</p>
                      <div className="list-content">
                        <div className="child">
                          {listOfMessage.map((item: any, index: number) => {
                            return (
                              <div
                                key={index}
                                className={
                                  item.role === "assistant"
                                    ? "mess-content mess-of-gpt"
                                    : "mess-content mess-of-sender"
                                }
                                onContextMenu={() => {}}
                              >
                                <div
                                  className={
                                    item.role === "assistant"
                                      ? "avt-gpt"
                                      : "avt-user"
                                  }
                                >
                                  <img
                                    src={
                                      item.role === "assistant"
                                        ? "/assets/icons/icon-gpt-02.png"
                                        : "/assets/icons/icon-user.png"
                                    }
                                    alt="icon"
                                  />
                                </div>
                                <div className="content">
                                  <p
                                    style={{
                                      textAlign: "left",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    {item.content}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                          {isLoading && (
                            <div>
                              <Typewriter
                                options={{
                                  strings: ["Hang on a sec..."],
                                  autoStart: true,
                                  loop: true,
                                }}
                              />
                              <LoadingRobot src={robot} alt="robot" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="input-chat">
                      <input
                        disabled={verifyStatus ? false : true}
                        defaultValue={message}
                        value={message}
                        placeholder={
                          verifyStatus
                            ? `${t("Please ask something...")}`
                            : `${t("Please connect wallet...")}`
                        }
                        type="text"
                        onChange={handleInputChange}
                        onKeyDown={(e) => {
                          handleEnter(e);
                        }}
                      />
                      <div
                        onClick={() => {
                          verifyStatus && message.length > 0 && !isLoading
                            ? handleSendMess()
                            : message.length > 0 && isLoading
                            ? toast.error("Please wait a sec")
                            : message.length <= 0
                            ? toast.error("Please ask something")
                            : open();
                        }}
                        className="btn-chat-ai"
                      >
                        <img
                          src="/assets/icons/icon-send.png"
                          alt="icon-send-mess"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="histoty">
                    <h3>
                      {t("History")} <div className="total">{history.length}</div>
                    </h3>
                    <div className="block-history">
                      <div className="list">
                        {history?.map((item: any, index: number) => {
                          return (
                            <div
                              key={index}
                              className="item-history"
                              onClick={() =>
                                setListOfMessage([
                                  {
                                    role: "user",
                                    content: history[index]?.question,
                                  },
                                  {
                                    role: "assistant",
                                    content: history[index]?.answer,
                                  },
                                ])
                              }
                            >
                              <p>{item.question}</p>
                            </div>
                          );
                        })}
                      </div>
                      <div className="new-chat">
                        <ButtonStyle onClick={handleNewChat}>
                          <button>
                            <span>{t("New Chat")}</span>
                            <img
                              src="/assets/icons/icon-go-staking.png"
                              alt="icon-go-staking"
                            />
                          </button>
                        </ButtonStyle>
                      </div>
                      {/* <div className="image">
                        <img
                          src="/assets/images/img-conner-chat.png"
                          alt="img-conner-chat"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundBanner>
    </BoxChatStyle>
  );
};

export default BoxChat;
