import { StarUpContainer } from './styled'

const StarUp = () => {
  return (
    <StarUpContainer>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </StarUpContainer>
  )
}

export default StarUp
