import { useTranslation } from "react-i18next";
import { BorderAnimation } from "../../Layout/styled";
import { ButtonCommon, CardStyle } from "../../page/Homev2/styled";
import { AnimatedText } from "../TextAnimation";
import { EcosystemStyle } from "./styled";

export default function Ecosystem() {
  const { t } = useTranslation();
  const ROW_ONE = [
    {
      title: `${t("AISOLARS SocialFi Mobile App")}`,
      content: `${t(
        "Your All-in-One web3 social hub. AISOLARS SocialFi App transcends the limitations of conventional social platforms, offering a multitude of features that foster meaningful connections, empower creators, and unlock the potential of web3 for everyone"
      )}`,
      image: "/assets/images/img-social.png",
      href: '#',
    },
    {
      title: `${t("AISOLARS Crypto Exchange & Swap")}`,
      content: `${t(
        "Trade smarter, faster, and more fun. Spot/future trading platform that seamlessly integrates AI-Powered bot trading technology."
      )}`,
      image: "/assets/images/img-macbook.png",
      href: '#',
    },
    {
      title: `${t("AISOL Game Hub")}`,
      content: `${t(
        "Explore Game Hub for a diverse range of games where players can earn rewards through tournaments, challenges, and DeFi integrations."
      )}`,
      image: "/assets/images/img-ip.png",
      href: '#',
    },
    {
      title: `${t("AISOL Staking")}`,
      content: `${t(
        "Discover the staking feature - a powerful tool supporting ecosystem growth and enabling passive income generation."
      )}`,
      image: "/assets/images/img-mac2.png",
      href: '#',
    },
  ];
  return (
    <EcosystemStyle id="ecosystem">
      <div className="container">
        <div className="title">
          <img src="/assets/images/Roadmap/Logo.png" alt="img-road" />
          <div className="eco-system">
            <AnimatedText data-aos="fade-right" el="p" text={t("ECOSYSTEM")} />
          </div>
        </div>
        <div className="content">
          <div className="row">
            {ROW_ONE.map((item: any, index: number) => {
              return (
                <CardStyle
                  key={index}
                  className="card-style"
                  data-aos="fade-up"
                >
                  <BorderAnimation />
                  <div className="inner">
                    <div className="header">
                      <h3>{item.title}</h3>
                      <p>{item.content}</p>
                      <ButtonCommon href={item.href}><span>Explore Features</span></ButtonCommon>
                    </div>
                    <div className="image">
                      <img src={item.image} alt="img-social" />
                    </div>
                  </div>
                </CardStyle>
              );
            })}
          </div>
        </div>
      </div>
    </EcosystemStyle>
  );
}
