import { useTranslation } from "react-i18next";
import { ButtonStyle } from "../../page/Homev2/styled";
import StarUp from "../StarUp";
import { AnimatedText } from "../TextAnimation";
import OurItem from "./OurItem";
import { VersionAndMissionStyle } from "./styled";

export default function VersionAndMission() {
  const { t } = useTranslation();
  const VERSION_MISSION = [
    {
      image: "/assets/icons/icon-version.png",
      title: `${t("Our Vision")}`,
      content: `${t(
        "In a world transformed by AI, AISOLARS envisions a future where everyone can leverage the power of artificial intelligence to achieve their full potential and reshape what's possible."
      )}`,
    },
    {
      image: "/assets/icons/icon-mission.png",
      title: `${t("Our Mission")}`,
      content: `${t(
        "AISOLARS is on a mission to democratize AI by creating a user-friendly platform that seamlessly integrates advanced AI functionalities to empower individuals to achieve their financial and creative goals."
      )}`,
    },
  ];
  return (
    <VersionAndMissionStyle id="versionAndMission">
      <div className="version-mission">
        {VERSION_MISSION.map((item: any, index: number) => {
          return <OurItem key={index} data={item} />;
        })}
      </div>
    </VersionAndMissionStyle>
  );
}
