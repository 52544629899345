import { useTranslation } from "react-i18next";
import { BorderAnimation } from "../../Layout/styled";
import { HomeStyle } from "../../page/Homev2/styled";
import { AnimatedText } from "../TextAnimation";
import { ClearlyRoadmapStyle, ImageLeftStyle, ImageRightStyle } from "./styled";

export default function ClearlyRoadmap() {
  const { t } = useTranslation();
  const LIST_DATA_CARD = [
    {
      icon: '/assets/images/Roadmap/icn_01.svg',
      content: [
        {
          text: `${t("Releasing of Landing Page")}`,
        },
        {
          text: `${t("Product Development")}`,
        },
        {
          text: `${t("Building Community")}`,
        },
        {
          text: `${t("Completing Marketing Strategy")}`,
        },
        {
          text: `${t("Branding")}`,
        },
      ],
      title: "Phase 01",
    },
    {
      icon: '/assets/images/Roadmap/icn_02.svg',
      content: [
        {
          text: `${t("Initiating AISOLARS Exchange")}`
        },
        {
          text: `${t("Integrating More AI Trading Bots")}`
        },
        {
          text: `${t("Releasing Staking Program")}`
        },
        {
          text: `${t("Completing Marketing Strategy")}`
        },
        {
          text: `${t("Angel Round & Seed Round")}`
        },
      ],
      title: "Phase 02",
    },
    {
      icon: '/assets/images/Roadmap/icn_03.svg',
      title: "Q1,Q2 - 2024",
      content: [
        {
          text: `${t("Launching SocialFi Features")}`
        },
        {
          text: `${t("Launching AI Features")}`
        },
        {
          text: `${t("Releasing Game Hub")}`
        },
        {
          text: `${t("Initiating Staking Feature")}`
        },
        {
          text: `${t("Private Sale & Public Sale")}`
        },
        {
          text: `${t("Listing On DEX/CEX")}`
        },
      ],
    },
    {
      icon: '/assets/images/Roadmap/icn_04.svg',
      title: "Phase 03",
      content: [
        {
          text: `${t("Updating Features On Exchange")}`
        },
        {
          text: `${t("Adding Trading Pairs")}`
        },
        {
          text: `${t("Listing On More CEXes")}`
        },
        {
          text: `${t("Launching More Games")}`
        },
      ],
    },
  ];
  return (
    <ClearlyRoadmapStyle id="roadmap">
      <ImageLeftStyle>
        <img src="/assets/images/img-left-road.png" alt="img-left-road" />
      </ImageLeftStyle>
      <ImageRightStyle>
        <img src="/assets/images/img-star-purple.png" alt="img-rolad-road" />
      </ImageRightStyle>
      <HomeStyle>
        <div className="gif-for-ai-metafi" data-aos="fade-up">
          <div className="gif">
            <img src="/assets/images/Roadmap/Logo.png" alt="img-road" />
          </div>
        </div>
        <div className="title-amfi-token">
          <AnimatedText data-aos="fade-up" el="h2" text={t("Roadmap")} />
        </div>
        <div className="list-card">
          {LIST_DATA_CARD.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="card-item"
                data-aos="fade-up"
                data-aos-delay={index * 150}
              >
                {/* <BorderAnimation /> */}
                <div className="icon">
                  <img src={item.icon} alt="" />
                </div>
                <div>
                  <h3>{item.title}</h3>
                  {item?.content?.map((inner:any, index:number)=>(
                    <p key={index} style={{ whiteSpace: "pre-line" }}>{inner.text}</p>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </HomeStyle>
    </ClearlyRoadmapStyle>
  );
}
