import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { auth } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { ForgotContainer } from "./styled";
import { sendPasswordResetEmail } from "firebase/auth";
import {
  AuthBlock,
  AuthForm,
  AuthLogo,
  ErrorState,
} from "../../../Layout/Auth/styled";
import { BorderAnimation } from "../../../Layout/styled";
import auth_logo from "../../../assets/Auth/form_icon.png";
import { ButtonStyle, ButtonStyle2 } from "../../Homev2/styled";
import StarUp from "../../../components/StarUp";
import { NavLink } from "react-router-dom";
import { AuthTitle, TextForgot } from "../Login/styled";
import InputCommon from "../../../components/Common/Input";
import { useTranslation } from "react-i18next";

type FormValuesProps = {
  email: string;
};

const Forgot = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
  });

  const defaultValues = {
    email: "",
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  // Submit
  const onSubmit = async (data: any) => {
    try {
      await sendPasswordResetEmail(auth, data.email);
      navigate("/auth/login");
      toast.success("Send success. Please check your email");
    } catch (error: any) {
      if (error.message === "Firebase: Error (auth/invalid-email).") {
        toast.error("Invalid email");
      }
    }
  };

  return (
    <ForgotContainer>
      <AuthForm>
        <AuthLogo>
          <img width={60} height={60} src={auth_logo} alt="logo" loading="lazy" />
        </AuthLogo>
        <AuthTitle>
          <h2>Forgot Password?</h2>
          <p>Enter your email<br />
            We’ll send you a reset password link to change your password
          </p>
        </AuthTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
              }}
            >
              <AuthBlock>
                <div>
                  <label>
                    {t("Email")}
                    <span>*</span>
                  </label>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <InputCommon
                        {...field}
                        status={errors.email ? "error" : ""}
                        name="email"
                        placeholder={t("Email")}
                      />
                    )}
                  />
                </div>
                {errors.email && (
                  <ErrorState>{errors.email?.message}</ErrorState>
                )}
              </AuthBlock>
            </div>
          </div>
          <ButtonStyle2>
            <BorderAnimation />
            <button type="submit">
              {/* <StarUp /> */}
              <span>{t("Send Reset Password Link")}</span>
            </button>
          </ButtonStyle2>
        </form>
        <TextForgot className="single">
          <NavLink to="/auth/login">{t("Back to login")}</NavLink>
        </TextForgot>
      </AuthForm>
    </ForgotContainer>
  );
};

export default Forgot;
