import styled from "styled-components";
import { SelectContainer } from "../Common/Select/styled";
import bg from "../../assets/Kyc/kyc_bg.png";
import { ButtonStyle } from "../../page/Homev2/styled";

export const KYCContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease-in;
  @media screen and (max-width: 767px) {
    padding: 0 10px;
  }
`;
export const KYCWrapper = styled.div`
  position: relative;
  max-width: 714px;
  width: 100%;
  min-height: 526px;
  padding: 50px 20px 20px;
  border-radius: 20px;
  border: 1px solid rgba(49, 49, 49, 0);
  background: linear-gradient(
    262deg,
    rgba(87, 0, 101, 0.28) 0.29%,
    rgba(26, 21, 59, 0.17) 49.79%,
    rgba(112, 0, 255, 0.05) 99.3%
  );
  backdrop-filter: blur(6px);
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(${bg}) no-repeat center / cover;
  }
  ${SelectContainer} {
    width: 200px;
  }
  ${ButtonStyle} {
    width: 50%;
    margin-left: auto;
    overflow: hidden;
    button {
      width: 100%;
      height: 50px;
      border-radius: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: auto;
    height: calc(100% - 100px);
    overflow-y: auto;
  }
  @media screen and (max-width: 650px) {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
  }
`;
export const KYCTitle = styled.p`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  background: linear-gradient(0deg, #b6c2f1 0%, #fff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const KYCDescription = styled.p`
  color: #a0aee5;
  font-size: 13px;
  margin-bottom: 30px;
  @media screen and (max-width: 650px) {
    margin-bottom: 10px;
  }
`;
export const KYCNation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  p {
    color: #fff;
    font-size: 14px;
  }
  @media screen and (max-width: 650px) {
    margin-bottom: 10px;
  }
`;
export const KYCDocumentID = styled.div`
  margin-bottom: 20px;
  @media screen and (max-width: 650px) {
    margin-bottom: 10px;
  }
`;
export const DocTitle = styled.p`
  color: #a0aee5;
  font-size: 12px;
  margin-bottom: 10px;
`;
export const KYCUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
  @media screen and (max-width: 430px) {
    flex-direction: column;
  }
`;
export const UploadBlockWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  max-height: 182px;
  height: 182px;
  border-radius: 12px;
  border: 1px dashed #fff;
  background: rgba(0, 0, 0, 0.21);
  backdrop-filter: blur(16px);
  color: #98a2b3;
  overflow: hidden;
  span {
    font-size: 14px;
    font-weight: 600;
    color: #fcfcfd;
    &.underline {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }
  div {
    text-align: center;
    p {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
  img {
    margin-bottom: 10px;
  }
  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
  }
  @media screen and (max-width: 430px) {
    height: 120px;
  }
  @media screen and (max-width: 650px) {
    padding: 20px 10px;
    div,
    span {
      font-size: 2.5vw;
      line-height: 1.5;
    }
  }
`;
export const ImageUploaded = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;
export const IconUpload = styled.img`
  width: 40px;
  height: 40px;
`;
export const UploadBlock = styled.div`
  width: 50%;
  cursor: pointer;
  @media screen and (max-width: 430px) {
    width: 100%;
  }
`;
export const UploadTitle = styled.p`
  color: #a0aee5;
  font-size: 14px;
  margin-bottom: 10px;
`;
export const KYCClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
