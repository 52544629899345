import styled from "styled-components";
import logoToken from "../../assets/Common/logoToken.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BorderAnimation } from "../../Layout/styled";
import { ButtonStyle2 } from "../../page/Homev2/styled";

const BottomBox = ({ setCollapse, collapse }: any) => {
  const { t } = useTranslation();
  const { tokenPrice }: any = useSelector((state: any) => state.user);
  return (
    <BoxWapper className={collapse ? "collapse" : ""}>
      <FlexSpaceBetweenBox>
        <img
          className="logotokent"
          src={logoToken}
          alt=""
          onClick={() => {
            setCollapse(false);
          }}
        />
        <p>Buy AISOL</p>
      </FlexSpaceBetweenBox>
      <BoxContent className={collapse ? "collapse" : ""}>
        <FlexSpaceBetweenBox>
          <h3>{t("Price")}</h3>
          <h4>{tokenPrice ? tokenPrice : "--"}$</h4>
        </FlexSpaceBetweenBox>
        <Link to="#">
          <ButtonStyle2>
            <BorderAnimation />
            <button type="submit">
              <span>
                {t("Buy AISOL")}
              </span>
            </button>
          </ButtonStyle2>
        </Link>
      </BoxContent>
    </BoxWapper>
  );
};
export default BottomBox;

const BoxWapper = styled.div`
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 12px;
  border: 1px solid rgba(54, 52, 52, 0.4);
  background: rgba(40, 40, 40, 0.70);
  box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
  backdrop-filter: blur(50px);
  border-radius: 12px;
  .logotokent {
    width: 32px;
    height: 32px;
    transition: all 0.15s linear;
  }
  &.collapse {
    padding: 10px;
    height: 58px;
    overflow: hidden;
    .logotokent {
      width: 30px;
      height: 30px;
    }
  }
`;
const FlexSpaceBetweenBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -10px;
    background: linear-gradient(90deg, #1F1F20 0%, #9B9B9B 45.1%, #1E1E1F 100%);
  }
  & > p {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
  }
`;
const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${FlexSpaceBetweenBox} {
    justify-content: space-between;
    &::before {
      content: none;
    }
  }
  ${ButtonStyle2} {
    min-height: 44px;
    width: 100%;
    border: 1px solid #2b2a2a;
  }
  h3,
  h4,
  p {
    transition: all 0.25s;
  }
  h3 {
    color: #fff;
    font-size: 15px;
  }
  h4 {
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    color: #32D583;
  }
  p {
    color: #667085;
    font-size: 13px;
    span {
      color: #fff;
      font-weight: 500;
    }
  }
  &.collapse {
    h3,
    h4,
    p {
      opacity: 0;
      font-size: 0px;
      visibility: hidden;
    }
  }
`;