import { useEffect, useState } from "react";
import SelectCommon from "../Common/Select";
import {
  DocTitle,
  KYCClose,
  KYCContainer,
  KYCDescription,
  KYCDocumentID,
  KYCNation,
  KYCTitle,
  KYCUpload,
  KYCWrapper,
} from "./styled";
import { gql, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import InputCommon from "../Common/Input";
import close from "../../assets/Kyc/close.svg";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux/store";
import { IsLoadingRedux, OpenModal } from "../../redux/slices/user";
import { ButtonStyle } from "../../page/Homev2/styled";
import { BorderAnimation } from "../../Layout/styled";
import StarUp from "../StarUp";
import Upload from "../Upload";
import axios from "axios";
import { useTranslation } from "react-i18next";

const SUBMIT_KYC = gql`
  mutation submitKYC(
    $country: String!
    $documentID: String!
    $frontURL: String!
    $backURL: String!
  ) {
    submitKYC(
      country: $country
      documentID: $documentID
      frontURL: $frontURL
      backURL: $backURL
    )
  }
`;

const KYCModal = () => {
  const {t} = useTranslation()
  const { isOpenModal }: any = useSelector((state: any) => state.user);
  const [urlFront, setUrlFront] = useState(null);
  const [urlBack, setUrlBack] = useState(null);
  const [idNumber, setIdNumber] = useState(null);
  const [submitKYC] = useMutation(SUBMIT_KYC);

  useEffect(() => {
    if (isOpenModal) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [isOpenModal]);

  // API Country
  const [countries, setCountries] = useState<any>([{ title: "Philippines", img: "" }]);
  const handleGetCountries = async () => {
    try {
      const { data } = await axios.get(
        "https://restcountries.com/v3.1/all?fields=name"
      );
      if (data) {
        const sortedCountries = data.map((item: any) => {
          return { title: item?.name.common, img: "" };
        }).sort((a: any, b: any) => {
          return a.title.localeCompare(b.title);
        });
        setCountries(sortedCountries);
      }
    } catch (error) {
      console.log("handleGetCountries err", error);
    }
  };

  useEffect(() => {
    handleGetCountries();
  }, []);

  const selectData = [
    { title: "United States", img: "" },
    { title: "China", img: "" },
    { title: "Vietnam", img: "" },
    { title: "French", img: "" },
    { title: "Hindi", img: "" },
    { title: "Korean", img: "" },
    { title: "Portuguese", img: "" },
    { title: "Russian", img: "" },
    { title: "Thai", img: "" },
    { title: "Others", img: "" },
  ];

  const [nation, setNation] = useState("United States");
  const handleChooseNation = (value: any) => {
    setNation(value);
  };

  const handleIdNumberChange = (e: any) => {
    setIdNumber(e.target.value);
  };

  // Handle Submit KYC
  const handleSubmit = async () => {
    try {
      if (!urlFront || !urlBack) {
        return toast.error("Please choose file!");
      }
      if (!idNumber) {
        return toast.error("Please enter document ID!");
      }
      const res: any = await submitKYC({
        variables: {
          country: nation,
          documentID: idNumber,
          frontURL: urlFront,
          backURL: urlBack,
        },
      });
      if (res) {
        dispatch(OpenModal(false));
        dispatch(IsLoadingRedux(true));
      }
      toast("Submit approval successfully");
    } catch (error) {
      toast.error("Submit fail!");
    }
  };

  return (
    <KYCContainer
      style={{
        opacity: isOpenModal ? "1" : "0",
        visibility: isOpenModal ? "visible" : "hidden",
      }}
    >
      <KYCWrapper>
        <KYCClose
          onClick={() => {
            dispatch(OpenModal(false));
          }}
        >
          <img src={close} alt="close" />
        </KYCClose>
        <KYCTitle>{t("Verify Your Account")}</KYCTitle>
        <KYCDescription>
          {t("Your account wasn’t verified. Please upload your document to verify your account.")}
        </KYCDescription>
        <KYCNation>
          <p>{t("Choose Nation")}</p>
          <SelectCommon data={countries} onChange={handleChooseNation} />
        </KYCNation>
        <KYCDocumentID>
          <DocTitle>{t("Document ID")}</DocTitle>
          <InputCommon
            name="doc"
            placeHolder={`${t("Input ID")}`}
            onChange={handleIdNumberChange}
          />
        </KYCDocumentID>
        <KYCUpload>
          <Upload
            isFront={true}
            title={`${t("Front of Document")}`}
            onUrl={setUrlFront}
          />
          <Upload title={`${t("Back of Document")}`} onUrl={setUrlBack} />
        </KYCUpload>
        <ButtonStyle onClick={handleSubmit}>
          <BorderAnimation />
          <button>
            <StarUp />
            <span>{t("Submit Approval")}</span>
          </button>
        </ButtonStyle>
      </KYCWrapper>
    </KYCContainer>
  );
};

export default KYCModal;
