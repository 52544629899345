import styled from "styled-components";

export const FooterAIMETAFI = styled.div`
  position: relative;
  padding: 60px 20px;
  background-color: #000;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding: 20px;
    max-width: 100%;
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
`;
export const FooterWrapper = styled.div`
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  .block-list-link {
    display: flex;
    align-items: flex-start;
    padding: 36px 0;
    gap: 40px;
    h3 {
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: bold;
      letter-spacing: 0.42px;
      background: linear-gradient(180deg, #b6c2f1 0%, #ebefff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    ul {
      li {
        a {
          color: #727272;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28.88px;
          letter-spacing: 0.57px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      padding: 36px 0;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: space-between;
      .list-link {
        width: 160px;
      }
    }
  }
  .list-social {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 35px;
    border-top: solid 1px rgba(161, 174, 229, 0.2);
    @media screen and (max-width: 769px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .icon-social {
      display: flex;
      align-items: center;
      gap: 14px;
      a {
        width: 48px;
        height: 48px;
        display: block;
        border-radius: 70px;
        border: 1px solid rgba(169, 169, 169, 0.11);
        border-radius: 70px;
        background: rgba(169, 169, 169, 0.11);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity 0.26s ease;
        &:hover {
          opacity: 0.7;
        }
      }
      @media screen and (max-width: 769px) {
        margin-bottom: 30px;
      }
    }
    .description {
      display: flex;
      gap: 50px;
      p {
        color: rgba(161, 174, 229, 0.7);
        font-family: Inter;
        font-size: 13.828px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.8px; /* 164.881% */
        letter-spacing: 0.45px;
      }
      @media screen and (max-width: 769px) {
        flex-direction: column;
        gap: 0px;
        p {
          font-size: 13px !important;
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    padding: 0;
  }
`;
export const BlockLogo = styled.div`
  p {
    color: #fff;
    font-family: Inter;
    font-size: 12.688px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.28px; /* 167.724% */
    letter-spacing: 0.28px;
    &:nth-child(2) {
      color: rgba(160, 174, 229, 0.7);
      font-family: Inter;
      font-size: 14.625px;
      font-style: normal;
      font-weight: 400;
      line-height: 24.32px; /* 166.291% */
      letter-spacing: 0.32px;
      margin: 18px 0;
    }
  }
`;
export const FooterWrapperTop = styled.div`
  display: flex;
  justify-content: space-between;
  ${BlockLogo} {
    max-width: 500px;
  }
  @media screen and (max-width: 767px) {
    display: block;
    ${BlockLogo} {
      max-width: unset;
    }
  }
`;

export const FooterLinks = styled.div`
  max-width: 220px;
  width: 100%;
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 22px 26px;
    a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 20px;
      backdrop-filter: blur(2px);
    }
  }
`;
export const FooterItem = styled.div`
  position: relative;
  max-width: 384px;
  width: 100%;
  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #fff;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 15px;
  flex-wrap: wrap;
  h2 {
    position: relative;
    font-size: 20px;
    color: #fff;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }
`;
export const FooterItemElm = styled.div<{ height?: string }>`
  height: fit-content;
  p {
    cursor: pointer;
  }
  a {
    display: block;
    font-size: 16px;
    font-weight: 300;
    color: #98a2b3;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;
export const LogoFooter = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
  a {
    display: block;
    max-width: 163px;
    width: 100%;
    img {
      width: 100%;
    }
  }
  p {
    margin-left: 16px;
    font-size: 24px;
  }
`;
export const BlockJoin = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: rgba(85, 85, 85, 0.27);
  backdrop-filter: blur(10px);
  padding: 20px;
  .block-left {
    max-width: 50%;
  }
  .text-highlight {
    color: #9e77ed;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
  }
  .text-normal {
    color: #d0d5dd;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
  }
  a {
    border-radius: 8px;
    background: #7f56d9;
    width: 101px;
    height: 34px;
    color: #fcfaff;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-left: 10px;
  }
  @media screen and (max-width: 767px) {
    background: unset;
    padding: 0;
    .block-left {
      display: none;
    }
    a {
      width: 100%;
      margin-left: 0;
    }
  }
`;
export const FooterAddress = styled.div`
  border-bottom: 1px solid rgba(161, 174, 229, 0.2);
  padding-bottom: 35px;
  display: flex;
  gap: 50px;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    gap: 30px;
  }
`;
export const FooterBlock = styled.div`
  max-width: 350px;
  width: 100%;
`;
export const AddressTitle = styled.p`
  color: #ced7ff;
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 5px;
`;
export const AddressText = styled.p`
  color: #ced7ff;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
`;
