import styled from "styled-components";

export const LoadingContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 88px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 80px);
  }
  .scene {
    position: relative;
    z-index: 2;
    height: 220px;
    width: 220px;
    display: grid;
    place-items: center;
  }
  .cube-wrapper {
    transform-style: preserve-3d;
    animation: bouncing 2s infinite;
  }
  .cube {
    transform-style: preserve-3d;
    transform: rotateX(45deg) rotateZ(45deg);
    animation: rotation 2s infinite;
  }
  .cube-faces {
    transform-style: preserve-3d;
    height: 80px;
    width: 80px;
    position: relative;
    transform-origin: 0 0;
    transform: translateX(0) translateY(0) translateZ(-40px);
  }
  .cube-face {
    position: absolute;
    inset: 0;
    background: #110d31;
    border: solid 1px #b391ff;
  }
  .cube-face.shadow {
    transform: translateZ(-80px);
    animation: bouncing-shadow 2s infinite;
  }
  .cube-face.top {
    transform: translateZ(80px);
  }
  .cube-face.front {
    transform-origin: 0 50%;
    transform: rotateY(-90deg);
  }
  .cube-face.back {
    transform-origin: 0 50%;
    transform: rotateY(-90deg) translateZ(-80px);
  }
  .cube-face.right {
    transform-origin: 50% 0;
    transform: rotateX(-90deg) translateY(-80px);
  }
  .cube-face.left {
    transform-origin: 50% 0;
    transform: rotateX(-90deg) translateY(-80px) translateZ(80px);
  }
  @keyframes rotation {
    0% {
      transform: rotateX(45deg) rotateY(0) rotateZ(45deg);
      animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
    }
    50% {
      transform: rotateX(45deg) rotateY(0) rotateZ(225deg);
      animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    }
    100% {
      transform: rotateX(45deg) rotateY(0) rotateZ(405deg);
      animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
    }
  }
  @keyframes bouncing {
    0% {
      transform: translateY(-40px);
      animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    }
    45% {
      transform: translateY(40px);
      animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }
    100% {
      transform: translateY(-40px);
      animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
    }
  }
  @keyframes bouncing-shadow {
    0% {
      transform: translateZ(-80px) scale(1.3);
      animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
      opacity: 0.05;
    }
    45% {
      transform: translateZ(0);
      animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
      opacity: 0.3;
    }
    100% {
      transform: translateZ(-80px) scale(1.3);
      animation-timing-function: cubic-bezier(0.76, 0.05, 0.86, 0.06);
      opacity: 0.05;
    }
  }
`;
export const TextLoading = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(98deg, #ceb6ff 2.24%, #864aff 112.02%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 10px;
`;
export const Bouncing = styled.div`
  display: flex;
  margin-left: 3px;
  & > div {
    position: relative;
    top: 5px;
    width: 5px;
    height: 5px;
    margin: 0 4px;
    border-radius: 50%;
    background-color: #864aff;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }
  & > div:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > div:nth-child(3) {
    animation-delay: 0.4s;
  }
  @keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translateY(-5px);
    }
  }
  @media screen and (max-width: 767px) {
    & > div {
      width: 2.6vw;
      height: 2.6vw;
    }
  }
`;
